import { useEffect, useState } from 'react';
import { fabric } from 'fabric';
import { v4 as uuidv4 } from 'uuid';
import { FabricJSCanvas, useFabricJSEditor } from 'fabricjs-react';
import { Label } from '../../../components/ui/label';
import { Input } from '../../../components/ui/input';
import { fontFamiliesDefault } from '../../../constants';
import { Button } from '../../../components/ui/button';
import { IMGURL } from '../../../constants/appConfig';
import { Separator } from '../../../components/ui/separator';
import { ProductComTemplateT, SideState } from '@/react-app-env';
import { useGetProductAttributesByCatSubCatMutation } from '../../../redux/slice/admin/AAttributeApiSlice';
import { SelectImage, SelectImagePrintable, showDelete, showDeleteIcon } from './SelectImage';
import { Checkbox } from '../../../components/ui/checkbox';
import parse from 'html-react-parser';
import { toast } from 'react-toastify';
import { useAddItemCartMutation } from '../../../redux/slice/CartApiSlice';
import { useDispatch } from 'react-redux';
import { addProductToCart } from '../../../redux/features/cartSlice';
import { FaAlignCenter, FaAlignJustify, FaAlignLeft, FaAlignRight, FaPlus, FaUnderline } from 'react-icons/fa';
import { FiMinus } from 'react-icons/fi';
import { IoMdClose } from 'react-icons/io';
import { GoItalic } from "react-icons/go";
import { TbLetterN } from "react-icons/tb";
import { ImBold } from "react-icons/im";
import { MdDelete, MdEdit } from 'react-icons/md';
import { IoText } from 'react-icons/io5';
import { Textarea } from '../../../components/ui/textarea';
import SelectOfficeSationary from './SelectOfficeSationary';

type Side = "front" | "back" | "left" | "right";
type SidesActive = "front" | "back" | "sides";

interface CanvasDesignCustomerProps {
  canvasHeight: number;
  canvasWidth: number;
  designTemplate: ProductComTemplateT;
  sidesFixedByAdmin?: { front?: boolean, back?: boolean, sides?: boolean, ePriceBack?: number, ePriceSides?: number }
}

const CanvasDesignCustomer = ({ canvasHeight, canvasWidth, designTemplate, sidesFixedByAdmin }: CanvasDesignCustomerProps) => {
  const { editor, onReady } = useFabricJSEditor();
  const [textOptions, setTextOptions] = useState({
    fontSize: 20,
    fontFamily: 'Arial',
    textColor: '#000000',
    fontStyle: 'normal' as 'normal' | 'italic' | 'oblique' | undefined,
    fontWeight: 'normal' as 'normal' | 'bold' | 'bolder' | 'lighter' | number | undefined,
    textAlign: 'left',
    underline: false,
    text: "Edit text You Added"
  });
  const [sizesCount, setSizesCount] = useState({ S: 0, M: 0, L: 0, XL: 0, XXL: 0 })
  // const { data: productAttributes } = useGetProductAttributesQuery(designTemplate.categoryId)
  // const attr = productAttributes?.result[0]
  // const hexColors = attr?.attributeValue && JSON.parse(attr?.attributeValue)
  const [modalOpen, setModalOpen] = useState(false);
  const [sheetOpen, setSheetOpen] = useState(false);
  const [groupSelect, setGroupSelect] = useState({});
  const [printableAreas, setPrintableAreas] = useState<(fabric.Rect | fabric.Path)[]>([]);
  const [sideSelected, setSideSelected] = useState<Side>("front");
  const [selectedColor, setSelectedColor] = useState<string>("");
  const [loadNextJson, setLoadNextJson] = useState<boolean>(false);
  const [triggerAddCart, setTriggerAddCart] = useState<boolean>(false);
  const [activateSide, setActivateSide] = useState<Record<SidesActive, boolean>>({ front: true, back: false, sides: false });
  const [canvasSides, setCanvasSides] = useState<Record<Side, SideState>>({
    front: { canvasUrl: designTemplate.templateUrl, previewUrl: designTemplate.templatePreviewUrl },
    back: { canvasUrl: designTemplate.templateBackUrl, previewUrl: "" },
    left: { canvasUrl: designTemplate.templateLeftSideUrl, previewUrl: "" },
    right: { canvasUrl: designTemplate.templateRightSideUrl, previewUrl: "" },
  })
  const [cartCanvasSides, setCartCanvasSides] = useState<Record<Side, SideState>>({
    front: { canvasUrl: "", previewUrl: "" },
    back: { canvasUrl: "", previewUrl: "" },
    left: { canvasUrl: "", previewUrl: "" },
    right: { canvasUrl: "", previewUrl: "" },
  })
  const [textDialogOpen, setTextDialogOpen] = useState<boolean>(false)
  const [loadingPreview, setLoadingPreview] = useState<boolean>(false)
  const [fixedTopLayerImg, setFixedTopLayerImg] = useState<boolean>(false)
  const [quantity, setQuantity] = useState(1)
  const currentUser = JSON.parse(localStorage.getItem("pfuser")!);
  const [AddItemCart] = useAddItemCartMutation()
  const dispatch = useDispatch()
  const [textFieldObjects, setTextFieldObjects] = useState<any[]>([]);
  const [allOptionsOfficeSet, setAllOptionsOfficeSet] = useState<boolean>(false)
  const [allOptionsOfficeSetCheck, setAllOptionsOfficeSetCheck] = useState<boolean>(false)
  const [extraAmount, setExtraAmount] = useState<number>(0)
  const [officeAttribute, setOfficeAttribute] = useState({ paperSize: {}, paperQuality: {}, paperType: {}, SerailNo: {}, instructions: "" })
  const [getProductAttColors, { data: fixedColorsAdmin }] = useGetProductAttributesByCatSubCatMutation()
  const [getProductAttSizes, { data: fixedSizesPriceAdmin }] = useGetProductAttributesByCatSubCatMutation()
  const hexColors = fixedColorsAdmin?.result.length > 0 && JSON.parse(fixedColorsAdmin?.result[0].attributeValue)
  const SizezByPrice = fixedSizesPriceAdmin?.result.length > 0 && JSON.parse(fixedSizesPriceAdmin?.result[0].attributeValue)


  useEffect(() => {
    getProductAttColors({ catId: designTemplate.categoryId, subcatId: designTemplate.subcategoryId, attributeName: "Color" })
  }, [getProductAttColors, designTemplate.categoryId, designTemplate.subcategoryId])

  useEffect(() => {
    getProductAttSizes({ catId: designTemplate.categoryId, subcatId: designTemplate.subcategoryId, attributeName: "Sizes Price" })
  }, [getProductAttSizes, designTemplate.categoryId, designTemplate.subcategoryId])


  const getPrintableAreas = (): (fabric.Rect | fabric.Path)[] => {
    if (!editor || !editor.canvas) return [];
    const objects = editor.canvas.getObjects();
    return objects.filter(obj => (obj as any).id === 'printableArea') as (fabric.Rect | fabric.Path)[];
  };

  const getClippingRectForArea = (area: fabric.Rect | fabric.Path) => {
    if (area.type === 'rect') {
      const dime = area.getBoundingRect();
      return new fabric.Rect({
        left: dime.left,
        top: dime.top,
        width: dime.width,
        height: dime.height,
        absolutePositioned: true,
      });
    } else if (area.type === 'path') {
      const pathData = (area as fabric.Path).path;
      return new fabric.Path(pathData, {
        left: area.left,
        top: area.top,
        angle: area.angle,
        scaleX: area.scaleX,
        scaleY: area.scaleY,
        absolutePositioned: true,
      });
    }
    return null;
  };

  const handleGroupClick = (group: fabric.Group) => {
    setGroupSelect(group);
    setTextDialogOpen(false)
    setModalOpen(true);
  };

  const attachEventListeners = () => {
    if (!editor || !editor.canvas) return;
    const objectsWithTextField = editor.canvas.getObjects()
      .filter(obj => obj.type === 'textbox' && (obj as any).textField) as fabric.Textbox[];
    setTextFieldObjects(objectsWithTextField);

    editor.canvas.allowTouchScrolling = true;
    editor.canvas.preserveObjectStacking = true;
    editor.canvas.forEachObject((obj: fabric.Object) => {

      obj.set({
        selectable: true,
        hasControls: true,
        hasBorders: true,
        evented: true, //new
      });

      obj.setControlsVisibility({
        tl: true,
        tr: true,
        bl: true,
        br: true,
        ml: false, // Middle left (horizontal scaling)
        mr: false, // Middle right (horizontal scaling)
        mt: false, // Middle top (vertical scaling)
        mb: false,
      });

      obj.set({
        cornerSize: 14,
        cornerColor: 'blue',
        cornerStyle: 'circle',
        transparentCorners: false,
      });

      if ((obj as any).id === 'printableArea') {
        obj.set({
          selectable: false,
          evented: false,
          hasControls: false,
          hasBorders: false,
        });
      }

      if ((obj as any).id === 'groupSelectImage') {
        const objCan = editor.canvas._objects;
        const objCanType = objCan.filter(ff => (ff as any).uuid && ff.visible === false);
        const groupSelect = objCanType.find(ff => (ff as any).uuid === (obj as any).uuid)
        obj.on('mousedown', () => {
          showDeleteIcon(obj, editor, groupSelect);
        });
      }

      if ((obj as any).id === 'customImage') {
        obj.on('mousedown', () => {
          showDelete(obj, editor);
        });
      }

      if ((obj as any).id === "fixedImage") {
        obj.set({
          selectable: false,
          hasControls: false,
          hasBorders: false,
          evented: false,//new
        });
        editor.canvas.bringToFront(obj)
        setFixedTopLayerImg(true)
      }

      if (obj.type === 'textbox' || obj.type === 'text' || obj.type === 'i-text') {
        const textObj = obj as fabric.IText;
        textObj.editable = true;
        textObj.evented = true;

        editor.canvas.bringToFront(textObj)//to get the text top when the layering is there
        // Store the initial printable area for the text object
        const printableArea = getPrintableAreas().find(area => {
          const left = textObj.left ?? 0;
          const top = textObj.top ?? 0;
          return area.containsPoint(new fabric.Point(left, top));
        });

        textObj.on('mousedown', (e) => {
          if (e.e.detail === 2) {
            textObj.enterEditing();
            editor.canvas.setActiveObject(textObj);
            editor.canvas.renderAll();
          }
        });

        textObj.on('editing:entered', () => {
          console.log('Entered editing mode for text:', textObj);
        });

        textObj.on('editing:exited', () => {
          console.log('Exited editing mode for text:', textObj);
        });

        textObj.set({
          lockMovementX: false,
          lockMovementY: false,
        });

        if (printableArea) {
          const clipRect = getClippingRectForArea(printableArea);
          if (clipRect) {
            textObj.set('clipPath', clipRect);
          }
        }
      }

      if (obj.type === 'group') {
        const group = obj as fabric.Group;
        group.on('mousedown', () => handleGroupClick(group));

        group.on('scaling', () => {
          console.log('Group scaling event');
        });

        group.forEachObject((subObj: fabric.Object) => {
          if (subObj.type === 'text' && (subObj as fabric.Text).text && ((subObj as fabric.Text).text as string).includes('Select Image')) {
            attachImageUploadListener(subObj);
          }
        });
      }
    });

    if (selectedColor.length > 0) {
      editor.canvas.setBackgroundColor(selectedColor, () => { editor.canvas.renderAll(); });
    }

    editor.canvas.on('object:moving', (e) => {
      const obj = e.target;

      if (obj) {
        const left = obj.left ?? 0;
        const top = obj.top ?? 0;
        const printableArea = getPrintableAreas().find(area => area.containsPoint(new fabric.Point(left, top)));

        if (obj.clipPath && (obj as any).id === "groupSelectImage") {
          const oldClipPath = obj.clipPath;
          obj.clipPath = oldClipPath;
          editor.canvas.renderAll();
        } else if (printableArea) {
          const clipRect = getClippingRectForArea(printableArea);
          if (clipRect) {
            obj.clipPath = clipRect;
            editor.canvas.renderAll();
          }
        }
      }
    });

    editor.canvas.on('object:scaling', (e) => {
      const obj = e.target;

      if (obj) {
        const left = obj.left ?? 0;
        const top = obj.top ?? 0;
        const printableArea = getPrintableAreas().find(area => area.containsPoint(new fabric.Point(left, top)));
        if (obj.clipPath) {
          const oldClipPath = obj.clipPath;
          obj.clipPath = oldClipPath;
          editor.canvas.renderAll();
        } else if (printableArea) {
          const clipRect = getClippingRectForArea(printableArea);

          if (clipRect) {
            obj.clipPath = clipRect;
            editor.canvas.renderAll();
          }
        }
      }
    });

    editor.canvas.on('selection:created', (e) => {
      const selectedObject = e?.selected && e?.selected[0];
      if (selectedObject && (selectedObject.type === 'textbox' || selectedObject.type === 'text' || selectedObject.type === 'i-text')) {
        openTextEditDialog(selectedObject);
      }
    });

    editor.canvas.on('selection:updated', (e) => {
      const selectedObject = e?.selected && e?.selected[0];
      if (selectedObject && (selectedObject.type === 'textbox' || selectedObject.type === 'text' || selectedObject.type === 'i-text')) {
        openTextEditDialog(selectedObject);
      }
    });

    editor.canvas.on('selection:cleared', () => {
      setTextDialogOpen(false)
      setTextOptions(pre => ({ ...pre, text: "Edit Your text Added" }))
    });
    editor.canvas.renderAll();
  };

  useEffect(() => {
    if (editor) {
      attachEventListeners();

      const handleKey = (e: KeyboardEvent) => {
        if (e.key === "Delete") {
          const object = editor.canvas.getActiveObject();
          if (object) editor.canvas.remove(object);
        }
      };

      window.addEventListener("keyup", handleKey);

      return () => {
        window.removeEventListener("keyup", handleKey);
      };
    }
  }, [editor]);

  useEffect(() => {
    const sideToLoadJson = canvasSides[sideSelected];
    if (cartCanvasSides[sideSelected].canvasUrl) {
      handleJSONImport(cartCanvasSides[sideSelected].canvasUrl)
    }
    else if (editor && editor.canvas && sideToLoadJson.canvasUrl) {
      const fetchDesign = async () => {
        try {
          const response = await fetch(`${IMGURL}${sideToLoadJson.canvasUrl}`);
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const json = await response.json();
          handleJSONImport(json, true);
        } catch (error) {
          console.error('Error fetching design JSON:', error);
        }
      };

      fetchDesign();
    }
  }, [editor, canvasSides, loadNextJson]);

  const openTextEditDialog = (textObject: any) => {
    setTextOptions(pre => ({ ...pre, text: textObject.text }))
    setTextDialogOpen(true);
  };

  const handleJSONImport = (jsonData: any, screenCheck = false) => {
    if (!editor || !editor.canvas) return;

    try {
      editor.canvas.clear();
      setFixedTopLayerImg(false)

      editor.canvas.loadFromJSON(jsonData, async () => {
        editor.canvas.forEachObject((obj: fabric.Object) => {
          obj.set({
            selectable: true,
            hasControls: true,
            hasBorders: true,
          });

          if ((obj as any).id === 'printableArea') {
            obj.set({
              selectable: false,
              evented: false,
              hasControls: false,
              hasBorders: false,
            });
          }
          obj.off("mousedown");
          obj.off("scaling");
        });

        const areas = getPrintableAreas();
        setPrintableAreas(areas);

        attachEventListeners();
        if (screenCheck) {
          const editorwidth = editor.canvas.getWidth();
          const scaleRatio = (editorwidth + 4) / canvasWidth;
          // const editorHeight = editor.canvas.getHeight();
          // const scaleRatio = (editorHeight+4)/ canvasHeight;
          // const screenWidth = window.outerWidth;
          const screenWidth = window.innerWidth;
          if (screenWidth < canvasWidth) {
            editor.canvas.getObjects().forEach((obj) => {
              obj.scaleX = (obj.scaleX ?? 1) * scaleRatio;
              obj.scaleY = (obj.scaleY ?? 1) * scaleRatio;
              obj.left = (obj.left ?? 0) * scaleRatio;
              obj.top = (obj.top ?? 0) * scaleRatio;

              if (obj.type === 'textbox' || obj.type === 'text' || obj.type === 'i-text') {
                const textObj = obj as fabric.Textbox;
                textObj.fontSize = (textObj.fontSize ?? 12) * scaleRatio;
              }
              obj.setCoords();
            });
            const backgroundImage = editor.canvas.backgroundImage;
            if (backgroundImage && backgroundImage instanceof fabric.Image) {
              backgroundImage.scaleX = (backgroundImage.scaleX ?? 1) * scaleRatio;
              backgroundImage.scaleY = (backgroundImage.scaleY ?? 1) * scaleRatio;
            }
          }
        }

        editor.canvas.allowTouchScrolling = true;
        editor.canvas.renderAll();
      });


    } catch (error) {
      console.error("Error parsing JSON:", error);
    }
  };

  const attachImageUploadListener = (obj: fabric.Object) => {
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.accept = "image/*";
    fileInput.style.display = "none";
    fileInput.onchange = (event) => handleImageUpload(event, obj);
    document.body.appendChild(fileInput);

    obj.on("mousedown", () => {
      fileInput.click(); // Trigger file input click when object is clicked
    });
  };

  const handleImageUpload = (event: Event, obj: fabric.Object) => {
    if (!editor || !editor.canvas) return;
    const input = event.target as HTMLInputElement;
    if (input.files && input.files[0]) {
      const file = input.files[0];
      const reader = new FileReader();
      reader.onload = (e) => {
        const imgElement = new Image();
        imgElement.src = e.target?.result as string;
        imgElement.onload = () => {
          const image = new fabric.Image(imgElement);
          image.scaleToWidth(obj.width ?? 0);
          image.scaleToHeight(obj.height ?? 0);
          image.set({
            left: obj.left,
            top: obj.top,
          });
          editor.canvas.remove(obj);
          editor.canvas.add(image);
          editor.canvas.renderAll();
        };
      };
      reader.readAsDataURL(file);
    }
  };

  const addText = async () => {
    if (!editor) return;
    const area = printableAreas[0]?.getBoundingRect();

    // if (!area) return;
    const textProps: fabric.ITextboxOptions = {
      left: area?.left ? area?.left + 1 : 0,
      top: area?.top ? area?.top + 1 : 0,
      width: 120,
      fontSize: textOptions.fontSize,
      fontFamily: textOptions.fontFamily,
      fill: textOptions.textColor,
      fontStyle: textOptions.fontStyle,
      fontWeight: textOptions.fontWeight,
      textAlign: textOptions.textAlign,
    };

    const text = new fabric.Textbox(textOptions.text, textProps);
    editor.canvas.add(text);

    text.on('mousedblclick', () => {
      text.enterEditing();
      text.selectAll();
    });

    editor.canvas.setActiveObject(text);
    editor.canvas.renderAll();
  };

  const handleColorChange = (color: string) => {
    if (!editor) return;
    if (designTemplate.categoryId === 1) {
      const confirmation = (activateSide.back || activateSide.sides) ? window.confirm(`Click on the print sides of bottom buttons to reflect the selected color.`) : true;
      console.log(confirmation);

      if (confirmation) {
        editor.canvas.setBackgroundColor(color, () => {
          editor.canvas.renderAll();
        });
        setSelectedColor(color);
      }
    } else {
      editor.canvas.setBackgroundColor(color, () => {
        editor.canvas.renderAll();
      });
      setSelectedColor(color);
    }
  };


  const handleChangeSides = async (value: Side) => {
    if (!editor) return;

    const json = await JSON.stringify(editor.canvas.toJSON(['id', 'attachEventListeners']));
    const base64Image = await editor.canvas.toDataURL();
    //const base64Image = "";
    await setCartCanvasSides((prev: any) => ({
      ...prev,
      [sideSelected]: { previewUrl: base64Image, canvasUrl: json },
    }));

    setSideSelected(value);

    const selectedSide = cartCanvasSides[value];
    if (selectedSide?.canvasUrl) {
      // await editor.canvas.loadFromJSON(selectedSide.canvasUrl, editor.canvas.renderAll.bind(editor.canvas));
      handleJSONImport(selectedSide.canvasUrl)
    } else {
      setLoadNextJson(pre => !pre)
    }
  };

  const handleLastSide = async (value: Side) => {
    if (!editor) return;

    const json = await JSON.stringify(editor.canvas.toJSON(['id', 'attachEventListeners']));
    const base64Image = await editor.canvas.toDataURL();
    //const base64Image = "";
    await setCartCanvasSides((prev: any) => ({
      ...prev,
      [sideSelected]: { previewUrl: base64Image, canvasUrl: json },
    }));

    setSideSelected(value);
    handleJSONImport(json)
    setLoadingPreview(true)
    setTimeout(() => { setTriggerAddCart(true); setLoadingPreview(false) }, 3000)
  };

  const handleAddCartBefore = async () => {
    const canvasObj = editor?.canvas.getObjects()
    const canvasObjFilText = canvasObj?.filter(obj=>(obj.type==="textbox" || obj.type === 'text' || obj.type === 'i-text') && !(obj as any).textValue)
    const canvasObjFilImg = canvasObj?.filter(obj=>obj.type==="group" && obj.visible ===true) 
   if(canvasObjFilImg?.length!>0){
    toast.error("Select the Images ")
    return;
   }else if(canvasObjFilText?.length!>0){
    toast.error("Change the text values")
    return;
   }else{
    await handleLastSide('front');
   }
    
    // setTimeout(()=>{ setLoading(true)},1000)
  };

  const handleAddCart = async () => {
    if (allOptionsOfficeSetCheck !== allOptionsOfficeSet) {
      toast.error("Select All the Options")
      return;
    }
    const totalQty = designTemplate.categoryId === 1 ? sizesCount.L + sizesCount.M + sizesCount.S + sizesCount.XL + sizesCount.XXL : quantity
    const extraPriceShirt = designTemplate.categoryId === 1 ?
      ((sizesCount.L * (SizezByPrice?.L ? SizezByPrice?.L : 0)) + (sizesCount.M * (SizezByPrice.M ? SizezByPrice?.M : 0)) + (sizesCount.S * (SizezByPrice.S ? SizezByPrice?.S : 0)) + (sizesCount.XL * (SizezByPrice.XL ? SizezByPrice?.XL : 0)) + (sizesCount.XXL * (SizezByPrice.XXL ? SizezByPrice?.XXL : 0))) / totalQty : 0;
    let attributesPrice = (activateSide?.back ? sidesFixedByAdmin?.ePriceBack! : 0) + (activateSide?.sides ? sidesFixedByAdmin?.ePriceSides! : 0) + (allOptionsOfficeSetCheck === allOptionsOfficeSet ? extraAmount : 0);
    attributesPrice += extraPriceShirt;
    const priceINRUpdated = designTemplate.priceINR + attributesPrice;
    if (totalQty <= 0) { toast.error("Count need to be atleast one"); return; }
    if (currentUser?.token) {
      try {
        const response = await AddItemCart({ customerId: currentUser.refId, attributesPrice, productId: designTemplate.productId, qty: totalQty, orderDesign: JSON.stringify(cartCanvasSides), orderAttributes: JSON.stringify({ ...sizesCount, Color: selectedColor, activateSide, ...officeAttribute }), readyToBuy: 1 });
        if ('error' in response) {
          console.error('error:', response.error);
          dispatch(addProductToCart({ ...designTemplate, priceINR: priceINRUpdated, attributesPrice, orderDesign: { ...cartCanvasSides }, totalQty, cartId: uuidv4(), orderAttributes: { ...sizesCount, Color: selectedColor, ...activateSide, ...officeAttribute } }))
          return;
        }
        const { success, result } = response?.data;

        //console.log(success, result);

        /*  if (success && result)navigate("/cart") */
      } catch (error) {
        console.error('Error adding to cart:', error);
      }
    } else {
      dispatch(addProductToCart({ ...designTemplate, priceINR: priceINRUpdated, attributesPrice, orderDesign: { ...cartCanvasSides }, totalQty, cartId: uuidv4(), orderAttributes: { ...sizesCount, Color: selectedColor, ...activateSide, ...officeAttribute } }))
    }
    toast.success("Added to cart")
  };

  useEffect(() => {
    if (editor && editor.canvas) {
      const screenWidth = window.innerWidth;
      //const screenWidth = window.outerWidth;
      const newCanvasWidth = screenWidth < canvasWidth ? screenWidth - 12 : canvasWidth;
      const scaleRatio = newCanvasWidth / canvasWidth;
      const newCanvasHeight = canvasHeight * scaleRatio;

      editor.canvas.setWidth(newCanvasWidth - 4);
      editor.canvas.setHeight(newCanvasHeight - 4);
      editor.canvas.allowTouchScrolling = true;
      editor.canvas.preserveObjectStacking = true;
      editor.canvas.renderAll();
    }
  }, [editor, canvasWidth, canvasHeight]);

  /*   const handleCanvasReady = (canvas:any) => {
      onReady(canvas);
      const screenWidth = window.innerWidth;
      console.log(screenWidth);
      const newCanvasWidth = screenWidth < canvasWidth ? screenWidth : canvasWidth;
      canvas.setWidth(newCanvasWidth);
      canvas.setHeight(canvasHeight);
      canvas.renderAll();
    }; */

  const handleInputFocus = (textObj: fabric.Textbox) => {
    if (!editor) return;
    editor.canvas.setActiveObject(textObj);
    editor.canvas.renderAll();
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
    setTextOptions({ ...textOptions, text: e.target.value });
    updateActiveText({ text: e.target.value,textValue:e.target.value });
  };

  const updateActiveText = (options: any) => {
    if (!editor) return;
    const activeObject = editor.canvas.getActiveObject() as fabric.Textbox & { textField?: string };   
    
    if (activeObject && activeObject.type === 'textbox') {
      setTextFieldObjects((prevObjects: any[]) =>
        prevObjects.map((obj: any) => {
          if (obj.textField === activeObject.textField) {
            return activeObject;
          }
          return obj;
        }));  
      activeObject.set(options);
      editor.canvas.renderAll();
    }
  };
  /* const updateActiveText = (options: Partial<fabric.ITextboxOptions>) => {
    const handleUnSelect = () => {
     if (!editor) return;
     editor.canvas.discardActiveObject();
     editor.canvas.renderAll();
     setTextDialogOpen(false);
     setTextOptions((prev: any) => ({ ...prev, text: "Edit Your text Added" }));
   }; */

  const textFieldsInput = textFieldObjects?.map((textObj, index) => (
    <div key={index} className="flex items-center border-2 my-2 p-[2px] border-gray-400 rounded-md">
      <span className="px-3 py-2 bg-blue-500 text-white"><IoText /></span>
      {(textObj as any).textField.toLowerCase() !== "address" ? <Input className="border-none w-72  focus-visible:ring-0 focus-visible:ring-transparent "
        value={(textObj as any).textValue|| ""}
        placeholder={textObj.textField}
        onFocus={() => handleInputFocus(textObj)}
        /* onBlur={() => handleUnSelect()} */
        onChange={(e) => handleInputChange(e)} /> :
        <Textarea className="border-none w-72  focus-visible:ring-0 focus-visible:ring-transparent "
        value={(textObj as any).textValue|| ""}
          placeholder={textObj.textField}
          /*  onBlur={() => handleUnSelect()} */
          onFocus={() => handleInputFocus(textObj)}
          onChange={(e) => handleInputChange(e)} />}

    </div>
  ))

  return (<div className='flex  flex-col items-center sm:mx-auto sm:w-full  gap-4 relative'>
    {!triggerAddCart && <div className="flex flex-wrap items-center gap-2 p-4 border w-full flex-grow  border-gray-300 rounded-lg shadow-md my-1">
      <div className='flex gap-3'>
        <Button type='button' variant={"outline"} onClick={addText} size={"sm"} className="bg-orange-600 text-white hover:bg-orange-800 px-4 py-2 rounded-md">
          Add Text
        </Button>
        <Button variant={"outline"} className='bg-orange-600 text-white hover:bg-orange-700 px-4 py-2 rounded-md' size={"sm"} onClick={() => setSheetOpen(true)}>Upload Image</Button>
      </div>
      {!triggerAddCart && (sidesFixedByAdmin && <div className='flex gap-4'>
        <p className='text-gray-600 font-semibold'>Print Sides:</p>
        {sidesFixedByAdmin.front && <div className="flex items-center space-x-2">
          <Checkbox id="front" disabled={true} checked={activateSide.front}
            onCheckedChange={async (value) => {
              setActivateSide(pre => ({ ...pre, back: Boolean(value) }));
              if (!value) {
                await handleChangeSides('front')
              }
            }} />
          <label
            htmlFor="front"
            className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
          >
            Front
          </label>
        </div>}
        {sidesFixedByAdmin.back && <div className="flex items-center space-x-2">
          <Checkbox id="back" checked={activateSide.back}
            onCheckedChange={async (value) => {
              setActivateSide(pre => ({ ...pre, back: Boolean(value) }));
              if (!value) {
                await handleChangeSides('front')
/*                     setCartCanvasSides(pre => ({ ...pre, back: { canvasUrl: "", previewUrl: "", side: false } }))
 */                  }
            }} />
          <label
            htmlFor="back"
            className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
          >
            Back
          </label>
        </div>}
        {sidesFixedByAdmin.sides && <div className="flex items-center space-x-2">
          <Checkbox id="sides" checked={activateSide.sides} onCheckedChange={async (value) => {
            setActivateSide(pre => ({ ...pre, sides: Boolean(value) }));
            if (!value) {
              await handleChangeSides('front')
            }
          }} />
          <label
            htmlFor="sides"
            className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
          >
            Sides
          </label>
        </div>}
      </div>)}
      {!triggerAddCart && (hexColors?.length > 0 && (
        <div className='flex gap-3 flex-wrap items-center'>
          <h4 className='font-medium text-md text-gray-700'>Color:</h4>
          {hexColors?.map((color: string) => (
            <Button
              key={color}
              value={color}
              className="rounded-full w-9 h-9 flex items-center justify-center shadow-md border border-gray-400"
              onClick={() => handleColorChange(color)}
              style={{ backgroundColor: color }}
            />
          ))}
        </div>
      ))}
      {textDialogOpen && <TextEditDialog setTextFieldObjects={setTextFieldObjects} setTextOptions={setTextOptions} textOptions={textOptions} editor={editor} setTextDialogOpen={setTextDialogOpen} />}
    </div>}
    {!triggerAddCart ? <div className={`max-w-full relative`}>
      <div className="flex gap-4 flex-wrap ">
        <div >
          {textFieldsInput}
        </div>
        {(canvasHeight && canvasWidth) && <div className={`md:h-[${canvasHeight}px]`}><FabricJSCanvas className={`border-2 shadow-lg  border-gray-800 `} onReady={onReady} /></div>}
      </div>
      {/*  {(canvasHeight && canvasWidth) && <FabricJSCanvas className={`border-2 shadow-lg  border-gray-800 w-[${canvasWidth}px] h-[${canvasHeight}px]`} onReady={onReady} />} */}
      {(sidesFixedByAdmin && activateSide.front) && <div className="flex gap-3 my-2 justify-center">
        {(activateSide.back || activateSide.sides) && <button onClick={() => { handleChangeSides('front') }} className={`text-sm p-1 px-2 rounded-md border-[1px] border-gray-500 ${sideSelected === "front" && "headermenu font-medium text-white"}`}>Front</button>}
        {activateSide.back && <button onClick={() => { handleChangeSides('back') }} className={`text-sm p-1 px-2 rounded-md border-[1px] border-gray-500 ${sideSelected === "back" && "headermenu text-white font-medium"}`}>Back</button>}
        {activateSide.sides && <button onClick={() => { handleChangeSides('left') }} className={`text-sm p-1 px-2 rounded-md border-[1px] border-gray-500 ${sideSelected === "left" && "headermenu font-medium text-white"}`}>Left</button>}
        {activateSide.sides && <button onClick={() => { handleChangeSides('right') }} className={`text-sm p-1 px-2 rounded-md border-[1px] border-gray-500 ${sideSelected === "right" && "headermenu font-medium text-white"}`}>Right</button>}
      </div>}
    </div> : <PriveiwSides setTriggerAddCart={setTriggerAddCart} activateSide={activateSide} designTemplate={designTemplate} cartCanvasSides={cartCanvasSides} />}
    <div className="flex flex-col gap-6 p-4 border w-full flex-grow  border-gray-300 rounded-lg shadow-lg">
      <div className="flex flex-col gap-4">
        {(designTemplate?.categoryId === 11 && triggerAddCart) && <SelectOfficeSationary officeAttribute={officeAttribute} setOfficeAttribute={setOfficeAttribute} setAllOptionsOfficeSetCheck={setAllOptionsOfficeSetCheck} setExtraAmount={setExtraAmount} setAllOptionsOfficeSet={setAllOptionsOfficeSet} catId={designTemplate?.categoryId} subcatId={designTemplate?.subcategoryId} />}

        <div className="flex flex-wrap gap-4 justify-between items-end">
          {triggerAddCart && ((designTemplate?.categoryId === 1) ? (
            <div className='flex flex-wrap gap-3  items-center'>
              <Label className='font-semibold'>Quantity:</Label>
              {["S", "M", "L", "XL", "XXL"].map((size) => {
                const sizeLabels: Record<string, string> = {
                  S: "Small",
                  M: "Medium",
                  L: "Large",
                  XL: "Extra Large",
                  XXL: "Double XL"
                };

                // Get the extra price for the current size from the SizezByPrice object
                const extraPrice = SizezByPrice?.[size] || 0; // Default to 0 if undefined

                return (
                  <div key={size} className="grid max-w-[150px] items-center gap-2 border p-3 rounded-lg shadow-md hover:shadow-lg transition-shadow">
                    <Label htmlFor={size} className="font-semibold text-gray-700">{sizeLabels[size]} ({size})</Label>
                    <Input
                      type="number"
                      min={0}
                      className="focus-visible:ring-offset-0 focus-visible:ring-transparent border border-gray-400 rounded-md px-2 py-1 text-sm"
                      onChange={(e) => setSizesCount({ ...sizesCount, [size]: +e.target.value })}
                      id={size}
                    />
                    <span className="text-xs text-gray-500">
                      Extra Price:
                      <span className="text-green-600 font-semibold"> +₹{extraPrice}</span>
                    </span>
                  </div>
                );
              })}
              {/*  <div className="grid max-w-24 items-center gap-2">
                <Label htmlFor="s" className="font-semibold text-gray-700">S</Label>
                <Input type="number" min={0} className='focus-visible:ring-offset-0 focus-visible:ring-transparent border border-gray-400 rounded-md px-2 py-1' onChange={(e) => setSizesCount({ ...sizesCount, S: +e.target.value })} id="s" />
              </div>
              <div className="grid max-w-24 items-center gap-2">
                <Label htmlFor="M" className="font-semibold text-gray-700">M</Label>
                <Input type="number" min={0} className='focus-visible:ring-offset-0 focus-visible:ring-transparent border border-gray-400 rounded-md px-2 py-1' onChange={(e) => setSizesCount({ ...sizesCount, M: +e.target.value })} id="M" />
              </div>
              <div className="grid max-w-24 items-center gap-2">
                <Label htmlFor="L" className="font-semibold text-gray-700">L</Label>
                <Input type="number" min={0} className='focus-visible:ring-offset-0 focus-visible:ring-transparent border border-gray-400 rounded-md px-2 py-1' onChange={(e) => setSizesCount({ ...sizesCount, L: +e.target.value })} id="L" />
              </div>
              <div className="grid max-w-24 items-center gap-2">
                <Label htmlFor="XL" className="font-semibold text-gray-700">XL</Label>
                <Input type="number" min={0} className='focus-visible:ring-offset-0 focus-visible:ring-transparent border border-gray-400 rounded-md px-2 py-1' onChange={(e) => setSizesCount({ ...sizesCount, XL: +e.target.value })} id="XL" />
              </div>
              <div className="grid max-w-24 items-center gap-2">
                <Label htmlFor="2XL" className="font-semibold text-gray-700">2XL</Label>
                <Input type="number" min={0} className='focus-visible:ring-offset-0 focus-visible:ring-transparent  border border-gray-400 rounded-md px-2 py-1' onChange={(e) => setSizesCount({ ...sizesCount, XXL: +e.target.value })} id="2XL" />
              </div> */}
            </div>
          ) : <div className="flex gap-2 items-center">
            <Label className='font-semibold'>Quantity:</Label>
            <div className="flex border-[1px]  border-gray-500 items-center gap-2 rounded-lg ">
              <Button variant="ghost" onClick={() => setQuantity((pre) => Math.max(1, pre - 1))} disabled={quantity === 1}>
                <FiMinus />
              </Button>
              <input
                type='number'
                min={1}
                className="w-20 border-none outline-none focus-visible:border-none focus-visible:ring-0 focus-visible:ring-transparent focus-visible:ring-offset-0 text-center"
                value={quantity}
                onChange={(e) => setQuantity(+e.target.value)}
              />
              <Button variant="ghost" onClick={() => setQuantity((pre) => pre + 1)}>
                <FaPlus />
              </Button>
            </div></div>
          )}
          {!triggerAddCart ? <Button size={"sm"} onClick={handleAddCartBefore} className='headermenu text-white hover:bg-blue-700 px-4 py-2 rounded-md justify-end mx-3'>Preview Design</Button> :
            <Button size={"sm"} onClick={handleAddCart} className='headermenu text-white hover:bg-blue-700 px-4 py-2 rounded-md justify-end mx-3'>Add To Cart</Button>
          }
        </div>
      </div>
      <Separator className='bg-gray-200 h-[1px]' />
      <div className="flex flex-col gap-2">
        <div className="flex items-center justify-between gap-4">
          {designTemplate?.productName && (
            <p className='font-bold text-xl '>{designTemplate?.productName}</p>
          )}
          {designTemplate?.priceINR && (
            <div className='flex gap-2'>
              <p className='font-semibold text-md text-gray-600'>Price:</p>
              <p className='mr-2 text-md font-semibold '>{Intl.NumberFormat("en-us", { style: "currency", currency: "INR" }).format(designTemplate?.priceINR
                + (allOptionsOfficeSetCheck === allOptionsOfficeSet ? extraAmount : 0)
                + (activateSide?.back ? sidesFixedByAdmin?.ePriceBack! : 0)
                + (activateSide?.sides ? sidesFixedByAdmin?.ePriceBack! : 0)
              )}</p>
            </div>
          )}
          {/*  {designTemplate?.priceINR && (
            <div className='flex gap-2'>
              <p className='font-semibold text-md text-gray-600'>Extra Price:</p>
              <p className='mr-2 text-md font-semibold '>{Intl.NumberFormat("en-us", { style: "currency", currency: "INR" }).format( +(( sizesCount.L + sizesCount.M + sizesCount.S + sizesCount.XL + sizesCount.XXL)?
               ((sizesCount.L*(SizezByPrice?.L?SizezByPrice?.L:0) )+ (sizesCount.M *(SizezByPrice.M?SizezByPrice?.M:0))+( sizesCount.S *(SizezByPrice.S?SizezByPrice?.S:0))+ (sizesCount.XL*(SizezByPrice.XL?SizezByPrice?.XL:0)) + (sizesCount.XXL*(SizezByPrice.XXL?SizezByPrice?.XXL:0)))/( sizesCount.L + sizesCount.M + sizesCount.S + sizesCount.XL + sizesCount.XXL):0)
              )}</p>
            </div>
          )} */}
        </div>
        {designTemplate?.productDescription && (
          <div>
            <p className='font-semibold text-md text-gray-600'>Description:</p>
            <div className='mx-4 text-md text-gray-800'>{parse(designTemplate?.productDescription)}</div>
          </div>
        )}
      </div>
      <Separator className='bg-gray-200 h-[1px]' />
      <div className='flex justify-center flex-col items-center gap-3 max-md:hidden'>
        <p className='font-bold text-xl'>Sample Image</p>
        <img src={`${IMGURL}${designTemplate?.sampleImageUrl}`} alt="sampleImage" className='max-w-[400px] border-2 border-gray-400' />
      </div>
      {modalOpen && <SelectImage editor={editor} setModalOpen={setModalOpen} groupSelect={groupSelect} fixedTopLayerImg={fixedTopLayerImg} />}
      {sheetOpen && <SelectImagePrintable editor={editor} setSheetOpen={setSheetOpen} printableAreas={printableAreas} />}
    </div>
    {loadingPreview && <LoadingPreview />}
  </div>
  )
};


export function LoadingPreview() {
  return (
    <div className='absolute top-0 left-0 w-full h-full bg-white opacity-50 shadow-lg z-50'>
      <div className="flex justify-center items-center h-full">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-800"></div>
      </div>
    </div>
  )
}

export const PriveiwSides = ({ cartCanvasSides, designTemplate, activateSide, setTriggerAddCart }: any) => {
  const [sideSelected, setSelectedSide] = useState("front")
  const [designPreview, setDesignPreview] = useState("")
  const handleChangeSides = (side: string) => {
    if (side === "left") {
      setDesignPreview(designTemplate.previewLeftSideUrl)
    }
    if (side === "right") {
      setDesignPreview(designTemplate.previewRightSideUrl)
    }
    if (side === "back") {
      setDesignPreview(designTemplate.previewBackUrl)
    }
    setSelectedSide(side)
  }
  return <div className=' max-w-full'>
    <div className="flex gap-4 justify-between my-4 items-center">
      <p className='font-semibold'>Preivew Image:</p>
      <Button size={"sm"} variant={"link"} onClick={() => setTriggerAddCart(false)} className='font-semibold text-orange-500 hover:text-orange-700 '>Edit Design <MdEdit size={20} className='mx-1 ' /></Button>
    </div>
    <div className="flex gap-2 flex-col max-w-full ">
      {cartCanvasSides?.front?.previewUrl && <img src={cartCanvasSides[sideSelected].previewUrl || `${IMGURL}${designPreview}`} alt="previewcart" className='w-full border-2 border-gray-500 shadow-md ' />}
      {(activateSide.front) && <div className="flex gap-3 my-2 justify-center">
        {(activateSide?.back || activateSide?.sides) &&
          <button onClick={() => { handleChangeSides('front') }} className={`text-sm p-1 px-2 rounded-md border-[1px] border-gray-500 ${sideSelected === "front" && "headermenu font-medium text-white"}`}>Front</button>}
        {activateSide?.back && <button onClick={() => { handleChangeSides('back') }} className={`text-sm p-1 px-2 rounded-md border-[1px] border-gray-500 ${sideSelected === "back" && "headermenu text-white font-medium"}`}>Back</button>}
        {activateSide?.sides && <button onClick={() => { handleChangeSides('left') }} className={`text-sm p-1 px-2 rounded-md border-[1px] border-gray-500 ${sideSelected === "left" && "headermenu font-medium text-white"}`}>Left</button>}
        {activateSide?.sides && <button onClick={() => { handleChangeSides('right') }} className={`text-sm p-1 px-2 rounded-md border-[1px] border-gray-500 ${sideSelected === "right" && "headermenu font-medium text-white"}`}>Right</button>}
      </div>}
    </div>
  </div>
}


const fontStyle = [{ id: 1, icon: <TbLetterN size={12} />, sty: 'normal' }, { id: 2, icon: <GoItalic size={12} />, sty: 'Italic' }]
const fontWeight = [{ id: 1, icon: <TbLetterN size={12} />, wgt: 'normal' }, { id: 2, icon: <ImBold size={12} />, wgt: 'bold ' }]
const textAlign = [
  { id: 1, icon: <FaAlignLeft size={12} />, agn: 'left' },
  { id: 2, icon: <FaAlignCenter size={12} />, agn: 'center' },
  { id: 3, icon: <FaAlignRight size={12} />, agn: 'right' },
  { id: 4, icon: <FaAlignJustify size={12} />, agn: 'justify' },
]
export function TextEditDialog({ editor, setTextFieldObjects, setTextOptions, textOptions, setTextDialogOpen }: any) {

  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTextOptions({ ...textOptions, text: e.target.value });
    updateActiveText({ text: e.target.value,textValue:e.target.value });
  };

  const handleFontSizeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTextOptions({ ...textOptions, fontSize: parseInt(e.target.value) });
    updateActiveText({ fontSize: parseInt(e.target.value) });
  };

  const handleFontSizeChangeM = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setTextOptions({ ...textOptions, fontSize: parseInt(e.target.value) });
    updateActiveText({ fontSize: parseInt(e.target.value) });
  };

  const handleFontFamilyChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setTextOptions({ ...textOptions, fontFamily: e.target.value });
    updateActiveText({ fontFamily: e.target.value });
  };

  const handleTextColorChange = (color: string) => {
    setTextOptions({ ...textOptions, textColor: color });
    updateActiveText({ fill: color });
  };

  const handleTextAlignmentChange = (alignment: string) => {
    setTextOptions({ ...textOptions, textAlign: alignment });
    updateActiveText({ textAlign: alignment });
  };

  const handleFontStyleChange = (style: 'normal' | 'italic') => {
    setTextOptions({ ...textOptions, fontStyle: style });
    updateActiveText({ fontStyle: style });
  };

  const handleFontWeightChange = (weight: 'normal' | 'bold' | number) => {
    setTextOptions({ ...textOptions, fontWeight: weight });
    updateActiveText({ fontWeight: weight });
  };

  const handleUnderlineChange = () => {
    setTextOptions({ ...textOptions, underline: !textOptions.underline });
    updateActiveText({ underline: !textOptions.underline });
  };

  const updateActiveText = (options:any) => {
    if (!editor) return;
    //const activeObject = editor.canvas.getActiveObject() as fabric.Textbox;
    const activeObject = editor.canvas.getActiveObject() as fabric.Textbox & { textField?: string };

    if (activeObject && activeObject.type === 'textbox') {
      setTextFieldObjects((prevObjects: any[]) =>
        prevObjects.map((obj: any) => {
          if (obj.textField === activeObject.textField) {
            return activeObject;
          }
          return obj;
        })
      );
      activeObject.set(options);
      editor.canvas.renderAll();
    }
  };

  const handleUnSelect = () => {
    editor.canvas.discardActiveObject();
    editor.canvas.renderAll();
    setTextDialogOpen(false);
    setTextOptions((prev: any) => ({ ...prev, text: "Edit Your text Added" }));
  };

  const deleteText = () => {
    if (editor && editor.canvas) {
      const activeObject = editor.canvas.getActiveObject();
      if (activeObject) {
        editor.canvas.remove(activeObject);
        setTextFieldObjects((prevObjects: any[]) =>
          prevObjects.filter((obj: any) => {
            return (obj.textField !== activeObject.textField) && obj
          }))
      }
    }
  };


  return (<>
    <div className={`relative max-sm:hidden  sm:max-w-full shadow-lg border-2 border-gray-600 z-10 bg-white p-2 rounded-lg`}>
      {/* <div className="flex justify-between gap-4 items-start">
        <p className='pb-2 text-gray-800 font-semibold '>Edit Text:</p>
        <Button onClick={handleUnSelect} variant={"link"} className='top-0 absolute right-0'><IoMdClose size={20} /></Button>
      </div> */}
      <div className="flex flex-wrap gap-4 items-center text-sm">
        <p className='pb-2 text-gray-800 font-semibold '>Edit Text:</p>
        <Input type="text" className='max-w-40 border-[1px] text-md font-medium border-gray-500 focus-visible:ring-transparent focus-visible:ring-0 ' value={textOptions.text} onChange={handleTextChange} />
        <Label className='flex items-center gap-1'>
          Size:
          <Input type="range" min="10" max="50" className='w-28' value={textOptions.fontSize} onChange={handleFontSizeChange} />
          <span className="font-semibold text-gray-700">{textOptions.fontSize}</span>
        </Label>
        <Label className='flex items-center gap-1'>
          Familiy:
          <select value={textOptions.fontFamily} onChange={handleFontFamilyChange} className='h-8 w-36 rounded-md border border-gray-400'>
            {fontFamiliesDefault.map((family: string) => <option value={family} key={family}>{family}</option>)}
          </select>
        </Label>
        <Label className='flex gap-1 items-center'>
          Color:
          <Input type="color" value={textOptions.textColor} onChange={(e) => handleTextColorChange(e.target.value)} className="w-[50px] h-9" />
        </Label>
        <Label className='flex gap-1 items-center'>
          Style:
          <div className='flex gap-1'>
            {fontStyle.map(style => (
              <Button key={style.id} variant={"link"} size={"sm"} onClick={() => handleFontStyleChange(style.sty as 'normal' | 'italic')}
                className={` rounded-md text-xs  ${textOptions.fontStyle === style.sty && 'bg-blue-500 text-white'}`}>
                {style.icon}
              </Button>
            ))}
            <Button variant={"link"} size={"sm"} className={`rounded-md text-xs p-1  ${textOptions.underline && 'bg-blue-500 text-white'}`}
              onClick={handleUnderlineChange}><FaUnderline size={12} /></Button>
          </div>
        </Label>
        <Label className='flex gap-1 items-center'>
          Align:
          <div>
            {textAlign.map(alignment => (
              <Button key={alignment.id} variant={"link"} onClick={() => handleTextAlignmentChange(alignment.agn)} className={` rounded-md text-xs ${textOptions.textAlign === alignment.agn && 'bg-blue-500 text-white'}`}>
                {alignment.icon}
              </Button>
            ))}
          </div>
        </Label>
        <Label className='flex gap-1 items-center'>
          Weight:
          <div className='flex gap-1 items-center'>
            {fontWeight.map(weight => (
              <Button key={weight.id} variant={"link"} size={"sm"} onClick={() => handleFontWeightChange(weight.wgt as 'normal' | 'bold' | number)} className={`rounded-md text-xs  ${textOptions.fontWeight === weight.wgt && 'bg-blue-500 text-white'}`}>
                {weight.icon}
              </Button>
            ))}
            <Input
              type="range"
              min="100"
              max="900"
              step="100"
              className='w-24'
              value={typeof textOptions.fontWeight === 'number' ? textOptions.fontWeight : 400}
              onChange={(e) => handleFontWeightChange(parseInt(e.target.value))}
            />
            <span className="font-semibold text-gray-700">
              {typeof textOptions.fontWeight === 'number' ? textOptions.fontWeight : '400'}
            </span>
          </div>
        </Label>
        <Button onClick={deleteText} variant={"ghost"}> <MdDelete size={20} color="red" /></Button>
        <Button onClick={handleUnSelect} variant={"link"} className='bottom-0-0 absolute right-0'><IoMdClose size={20} /></Button>
      </div>
    </div>
    <div className="fixed sm:hidden bottom-0 left-0 right-0 bg-white p-4 px-2 shadow-lg border-t-2 border-gray-600 z-10 flex items-center justify-between">
      <Button onClick={handleUnSelect} variant={"ghost"} className='p-0 px-2' ><IoMdClose size={18} /></Button>
      <div className="flex-1 flex overflow-x-auto gap-4 items-center justify-center whitespace-nowrap">
        <div className="flex gap-4 min-w-full">
          <Input type="text" className="min-w-[150px] border-[1px] text-md font-medium border-gray-500 focus-visible:ring-transparent focus-visible:ring-0" value={textOptions.text} onChange={handleTextChange} />
          <Label className="flex items-center gap-1">
            Size:
            <select
              value={textOptions.fontSize}
              onChange={handleFontSizeChangeM}
              className="h-8 w-20 rounded-md border border-gray-400"
            >
              {Array.from({ length: 10 }, (_, i) => i + 10).map((size) => (
                <option value={size} key={size}>
                  {size}
                </option>
              ))}
              {Array.from({ length: 16 }, (_, i) => 20 + i * 2).map((size) => (
                <option value={size} key={size}>
                  {size}
                </option>
              ))}
            </select>
          </Label>
          <Label className="flex items-center gap-2">
            Font:
            <select value={textOptions.fontFamily} onChange={handleFontFamilyChange} className="h-8 w-36 rounded-md border border-gray-400">
              {fontFamiliesDefault.map((family: string) => <option value={family} key={family}>{family}</option>)}
            </select>
          </Label>

          <Label className="flex gap-1 items-center">
            Color:
            <Input type="color" value={textOptions.textColor} onChange={(e) => handleTextColorChange(e.target.value)} className="w-[50px] h-9" />
          </Label>

          <Label className="flex gap-1 items-center">
            Style:
            <div className="flex gap-1">
              {fontStyle.map(style => (
                <Button key={style.id} variant={"link"} size={"sm"} onClick={() => handleFontStyleChange(style.sty as 'normal' | 'italic')}
                  className={` rounded-md ${textOptions.fontStyle === style.sty && 'bg-blue-500 text-white'}`}>
                  {style.icon}
                </Button>
              ))}
              <Button variant={"link"} size={"sm"} className={`rounded-md ${textOptions.underline && 'bg-blue-500 text-white'}`}
                onClick={handleUnderlineChange}><FaUnderline /></Button>
            </div>
          </Label>

          <Label className="flex gap-2 items-center">
            Align:
            <div className="flex gap-1">
              {textAlign.map(alignment => (
                <Button key={alignment.id} variant={"link"} onClick={() => handleTextAlignmentChange(alignment.agn)} className={` rounded-md ${textOptions.textAlign === alignment.agn && 'bg-blue-500 text-white'}`}>
                  {alignment.icon}
                </Button>
              ))}
            </div>
          </Label>

          <Label className="flex gap-2 items-center">
            Font Weight:
            <div className="flex gap-2">
              {fontWeight.map(weight => (
                <Button key={weight.id} variant={"link"} size={"sm"} onClick={() => handleFontWeightChange(weight.wgt as 'normal' | 'bold' | number)} className={`rounded-md ${textOptions.fontWeight === weight.wgt && 'bg-blue-500 text-white'}`}>
                  {weight.icon}
                </Button>
              ))}

            </div>
          </Label>
          <Button onClick={deleteText} variant={"ghost"}> <MdDelete size={20} color="red" /></Button>
        </div>

      </div>

    </div>

  </>);
}

export default CanvasDesignCustomer;