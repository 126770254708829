

import * as z from "zod"

const DesLong = (value: string) => {
    const lines = value.split("\n").filter((line) => line.trim() !== "");
    return lines.length >= 0;
  };
  const DesShort = (value: string) => {
    const lines = value.split("\n").filter((line) => line.trim() !== "");
    return lines.length >= 0;
  };
  const isImage = (file: File): boolean => {
    return typeof file.type === 'string' && file.type.startsWith('image/');
  };
  
  const isPDF = (file: File): boolean => {
    return typeof file.type === 'string' && file.type === 'application/pdf';
  };
  
  const fileSchema = z.custom<File>((file: unknown) => {
    if (file instanceof File) {
      if (isImage(file) || isPDF(file)) {
        return file;
      } else {
        throw new Error('Invalid file type. Please upload an image or a PDF.');
      }
    } else {
      throw new Error('Invalid file type. Please upload an image or a PDF.');
    }
  });
  
  


const productFormSchema = z.object({
    productName: z.string().min(3, {
      message: "Name must be at least 3 characters.",
    }),
    productDescription: z.string().min(80, {
      message: "Description must be at least 80 characters.",
    }),
    thumbnail: z.string().min(80, {
      message: "Description must be at least 80 characters.",
    }),
   /*  productDescription: z.string().refine((value) => DesLong(value), {
      message: "Description must be at least 8 lines.",
    }), */
    categoryId: z.number().positive({
      message: "categoryId must be a positive number.",
    }),
    priceINR: z.number().positive({
      message: "priceINR must be a positive number.",
    }),
    
    subcategoryId: z.number().positive({
      message: "subcategoryId must be a positive number.",
    }),
  });

  function isValidBase64(value:string) {
    if (typeof value !== "string") return false;
    return value.includes("data:image") || value.includes("templates")
  }

export const templateFormSchema = z.object({
    categoryId: z.number().positive({
      message: "categoryId must be a positive number.",
    }),
    subcategoryId: z.number().positive({
      message: "subcategoryId must be a positive number.",
    }),
    templateUrl:z.string(),
    templateSample: z.string().refine((value) => isValidBase64(value), {
      message: "Thumbnail must be a valid Base64 encoded string",
    }),
  });
 
 export const formDimensionsSchema = z.object({
    canvasWidth: z.number().positive({
      message: "Canvas width must be a positive number.",
    }).min(1, {
      message: "Canvas width must be at least 1.",
    }),
    canvasHeight: z.number().positive({
      message: "Canvas height must be a positive number.",
    }).min(1, {
      message: "Canvas height must be at least 1.",
    }),
  });
  export default productFormSchema