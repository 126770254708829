import { NavLink, useNavigate } from "react-router-dom";
import NavigationLinks from "./NavigationLinks";
import HeaderIcons from "./HeaderIcons";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import MobileNav from "../RootLayout/MobileNav";
import { NavLinksH } from "../../../constants";
import { useGetAllCategoryQuery } from "../../../redux/slice/CategoryApiSlice";
import {
  Sheet,
  SheetContent,
  SheetTrigger,
} from "../../ui/sheet";
import { FaBars, FaChevronRight } from "react-icons/fa";
import { Separator } from "../../../components/ui/separator";
import { Button } from "../../../components/ui/button";
import { useState } from "react";
import { IMGURL } from "../../../constants/appConfig";
import { HiMiniBars3BottomLeft } from "react-icons/hi2";

const Header = () => {
  const { auth } = useSelector((state: RootState) => state.authCon)
  const { data: categories } = useGetAllCategoryQuery("categoriesList", { refetchOnFocus: true, refetchOnMountOrArgChange: true, });
  const [open, setOpen] = useState(false)
  const navigate = useNavigate()
  const handleClose = () => {
    setOpen(false)
  }

  return (
    <header className="text-white text-xl headersticky top-0 z-50">
      <nav className="w-full ">
        <div className="w-full bg-white flex justify-center">
          <div className="w-full text-black md:max-w-6xl flex flex-row gap-8 justify-between items-center">
            <MobileNav />
            <div className="flex gap-4 items-center max-md:hidden ">
              <NavLink to="/contact-us" className="text-sm font-semibold">
                Contact Us
              </NavLink>
              <NavLink to="/track-order" className="text-sm font-semibold">
                Track Order
              </NavLink>
            </div>
            <div>
              <NavLink to="/">
                <img alt="logo" src="/assets/logo/printferry_logo.png" className="h-16 my-1" />
              </NavLink>
            </div>
            <div className="flex gap-4 justify-end">
              <HeaderIcons />
            </div>
          </div>
        </div>
        <div className="w-full headermenu">
          <div className="w-full wrapper flex-wrap gap-3 md:flex-row flex-col justify-between md:flex hidden ">
            <Sheet open={open} onOpenChange={() => {
              if (!open) {
                setOpen(true);
              } else {
                handleClose();
              }
            }}>
              <SheetTrigger className="align-middle md:block hidden">
                <HiMiniBars3BottomLeft  size="26px" color="white" />
              </SheetTrigger>
              <SheetContent className=" thin-scrollbar flex flex-col gap-6 bg-white overflow-y-auto" side={"left"}>
                <img
                  src="/assets/logo/logo1.png"
                  alt="logo"
                  width={128}
                  height={38}
                />
                <Separator className="border border-gray-100" />
                <div className="flex  gap-4  flex-col text-lg font-semibold justify-start items-start w-full">
                  {categories?.result?.map((nav:any) => <Button key={nav.id} onClick={() => { navigate(`/category/${nav.id}`); handleClose() }} variant={"link"}
                    className="hover:text-red-700 font-medium text-lg w-full flex justify-between"><span className="flex gap-3"><img src={`${IMGURL}${nav.imageUrl}`} alt={nav.categoryName} width={35} height={35} className="rounded-sm" />{nav.categoryName}</span><FaChevronRight /></Button>
                  )}
                </div>
              </SheetContent>
            </Sheet>
            {NavLinksH.map(nav => <NavLink key={nav.id} to={`/category/${nav.id}`} className="hover:text-blue-400 text-md">{nav.categoryName}</NavLink>
            )}
          </div>
        </div>
      </nav>
    </header>

  );
};

export default Header;


