import { Suspense, useEffect, useState } from 'react'
import { Canvas } from '@react-three/fiber'
//import { PerspectiveCamera } from 'three';
import { Environment, OrbitControls } from '@react-three/drei'
import { FabricJSCanvas, useFabricJSEditor } from 'fabricjs-react'
import { fabric } from 'fabric'
import { Button } from '../../ui/button'
import MugModel from '../Mug/MugModel'
import { useAddItemCartMutation } from '../../../redux/slice/CartApiSlice'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify';
/* import { addSareeToCart } from '../../../redux/features/cartSlice'
 */import { IMGURL } from '../../../constants/appConfig'
import { ProductComTemplateT, SideState } from '@/react-app-env'
import { v4 as uuidv4 } from 'uuid';
import { useGetProductAttributesByCatSubCatMutation } from '../../../redux/slice/admin/AAttributeApiSlice'
import { Separator } from '../../../components/ui/separator';
import { SelectImageMug, SelectImagePrintableMug, showDelete, showDeleteIcon } from './SelectImage'
import { Label } from '../../../components/ui/label'
import { Input } from '../../../components/ui/input'
import { fontFamiliesDefault } from '../../../constants'
import parse from 'html-react-parser';
import { addProductToCart } from '../../../redux/features/cartSlice'
import { FiMinus } from 'react-icons/fi'
import { FaAlignCenter, FaAlignJustify, FaAlignLeft, FaAlignRight, FaPlus, FaUnderline } from 'react-icons/fa'
import { IoMdClose } from 'react-icons/io'
import { TbLetterN } from 'react-icons/tb'
import { GoItalic } from 'react-icons/go'
import { ImBold } from 'react-icons/im'
import { MdDelete } from 'react-icons/md'


type Side = "front" | "back" | "left" | "right";
interface MugDesignCanvasProps {
  canvasHeight: number;
  canvasWidth: number;
  designTemplate: ProductComTemplateT
}
const MugDesignCanvas = ({ canvasHeight, canvasWidth, designTemplate }: MugDesignCanvasProps) => {
  const { editor, onReady } = useFabricJSEditor()
  const [visibleMug, setvisibleMug] = useState(true)
  // const [visibleCadre, setvisibleCadre] = useState(true)
  // const { data: productAttributes } = useGetProductAttributesQuery(designTemplate.categoryId)
  // const attr = productAttributes?.result[0]
  // const hexColors = attr?.attributeValue && JSON.parse(attr?.attributeValue)
  const [modalOpen, setModalOpen] = useState(false);
  const [sheetOpen, setSheetOpen] = useState(false);
  const [groupSelect, setGroupSelect] = useState({});
  const [printableAreas, setPrintableAreas] = useState<(fabric.Rect | fabric.Path)[]>([]);
  const [mugColor, setMugColor] = useState("#ff0000")
  const [triggerAddCart, setTriggerAddCart] = useState<boolean>(false);
  const [quantity, setQuantity] = useState(1)
  const [textDialogOpen, setTextDialogOpen] = useState<boolean>(false)
  const [fixedTopLayerImg, setFixedTopLayerImg] = useState<boolean>(false)
  const [textOptions, setTextOptions] = useState({
    fontSize: 20,
    fontFamily: 'Arial',
    textColor: '#000000',
    fontStyle: 'normal' as 'normal' | 'italic' | 'oblique' | undefined,
    fontWeight: 'normal' as 'normal' | 'bold' | 'bolder' | 'lighter' | number | undefined,
    textAlign: 'left',
    underline: false,
    text: "Edit text You Added"
  });
  const [cartCanvasSides, setCartCanvasSides] = useState<Record<Side, SideState>>({
    front: { canvasUrl: "", previewUrl: "" },
    back: { canvasUrl: "", previewUrl: "" },
    left: { canvasUrl: "", previewUrl: "" },
    right: { canvasUrl: "", previewUrl: "" },
  })
  const currentUser = JSON.parse(localStorage.getItem("pfuser")!);
  const [AddItemCart] = useAddItemCartMutation()
  const dispatch = useDispatch()
  const [getProductAttColors,{data:fixedColorsAdmin}] =useGetProductAttributesByCatSubCatMutation()
  /*  const cameraRef = useRef<PerspectiveCamera>(null);
   const controlsRef = useRef<any>(null); */
   const hexColors = fixedColorsAdmin?.result.length>0&&  JSON.parse(fixedColorsAdmin?.result[0].attributeValue)

 
 useEffect(()=>{
   getProductAttColors({catId:designTemplate.categoryId,subcatId:designTemplate.subcategoryId,attributeName:"Color"})
 },[getProductAttColors,designTemplate.categoryId,designTemplate.subcategoryId])

  const openTextEditDialog = (textObject: any) => {
    setTextOptions(pre => ({ ...pre, text: textObject.text }))
    setTextDialogOpen(true);
  };

  const getPrintableAreas = (): (fabric.Rect | fabric.Path)[] => {
    if (!editor || !editor.canvas) return [];
    const objects = editor.canvas.getObjects();
    return objects.filter(obj => (obj as any).id === 'printableArea') as (fabric.Rect | fabric.Path)[];
  };

  const getClippingRectForArea = (area: fabric.Rect | fabric.Path) => {
    if (area.type === 'rect') {
      const dime = area.getBoundingRect();
      return new fabric.Rect({
        left: dime.left,
        top: dime.top,
        width: dime.width,
        height: dime.height,
        absolutePositioned: true,
      });
    } else if (area.type === 'path') {
      const pathData = (area as fabric.Path).path;
      return new fabric.Path(pathData, {
        left: area.left,
        top: area.top,
        angle: area.angle,
        scaleX: area.scaleX,
        scaleY: area.scaleY,
        absolutePositioned: true,
      });
    }
    return null;
  };

  const handleGroupClick = (group: fabric.Group) => {
    setGroupSelect(group);
    setModalOpen(true);
  };

  const attachEventListeners = () => {
    if (!editor || !editor.canvas) return;

    editor.canvas.allowTouchScrolling = true;
    editor.canvas.preserveObjectStacking = true;
    editor.canvas.forEachObject((obj: fabric.Object) => {

      obj.set({
        selectable: true,
        hasControls: true,
        hasBorders: true,
        evented: true, //new
      });
      obj.setControlsVisibility({
        tl: true,
        tr: true,
        bl: true,
        br: true,
        ml: false, // Middle left (horizontal scaling)
        mr: false, // Middle right (horizontal scaling)
        mt: false, // Middle top (vertical scaling)
        mb: false,
      });

      obj.set({
        cornerSize: 14,         
        cornerColor: 'blue', 
        cornerStyle: 'circle',   
        transparentCorners: false, 
      });
      if ((obj as any).id === 'printableArea') {
        obj.set({
          selectable: false,
          evented: false,
          hasControls: false,
          hasBorders: false,
        });
      }
      
      if ((obj as any).id === 'groupSelectImage') {
        const objCan = editor.canvas._objects;
        const objCanType = objCan.filter(ff=>(ff as any).uuid && ff.visible===false);
        const groupSelect = objCanType.find(ff=>(ff as any).uuid===(obj as any).uuid)
        obj.on('mousedown', () => {
          showDeleteIcon(obj, editor, groupSelect);
        });
      }

      if ((obj as any).id === 'customImage') {
        obj.on('mousedown', () => {
          showDelete(obj, editor);
        });
      }

      if ((obj as any).id === "fixedImage") {
        obj.set({
          selectable: false,
          hasControls: false,
          hasBorders: false,
          evented: false,//new
        });
        editor.canvas.bringToFront(obj)
        setFixedTopLayerImg(true)
      }

      if (obj.type === 'textbox' || obj.type === 'text' || obj.type === 'i-text') {
        const textObj = obj as fabric.IText;
        textObj.editable = true;
        textObj.evented = true;
        editor.canvas.bringToFront(textObj)
        // Store the initial printable area for the text object
        const printableArea = getPrintableAreas().find(area => {
          const left = textObj.left ?? 0;
          const top = textObj.top ?? 0;
          return area.containsPoint(new fabric.Point(left, top));
        });

        textObj.on('mousedown', (e) => {
          if (e.e.detail === 2) {
            textObj.enterEditing();
            editor.canvas.setActiveObject(textObj);
            editor.canvas.renderAll();
          }
        });

        textObj.on('editing:entered', () => {
          console.log('Entered editing mode for text:', textObj);
        });

        textObj.on('editing:exited', () => {
          console.log('Exited editing mode for text:', textObj);
        });

        textObj.set({
          lockMovementX: false,
          lockMovementY: false,
        });

       /*  if (printableArea) {
          const clipRect = getClippingRectForArea(printableArea);
          if (clipRect) {
            textObj.set('clipPath', clipRect);
          }
        } */
      }

      if (obj.type === 'group') {
        const group = obj as fabric.Group;

        group.on('mousedown', () => handleGroupClick(group));

        group.on('scaling', () => {
          console.log('Group scaling event');
        });

        group.forEachObject((subObj: fabric.Object) => {
          if (subObj.type === 'text' && (subObj as fabric.Text).text && ((subObj as fabric.Text).text as string).includes('click to select image')) {
            attachImageUploadListener(subObj);
          }
        });
      }
    });

    editor.canvas.on('object:moving', (e) => {
      const obj = e.target;

      if (obj) {
        const left = obj.left ?? 0;
        const top = obj.top ?? 0;
        const printableArea = getPrintableAreas().find(area => area.containsPoint(new fabric.Point(left, top)));
        if (obj.clipPath && (obj as any).id === "groupSelectImage") {
          const oldClipPath = obj.clipPath;
          obj.clipPath = oldClipPath;
          editor.canvas.renderAll();
        } else if (printableArea && !(obj.type === 'textbox' || obj.type === 'text' || obj.type === 'i-text')) {
          const clipRect = getClippingRectForArea(printableArea);
          if (clipRect) {
            obj.clipPath = clipRect;
            editor.canvas.renderAll();
          }
        }
      }
    });

    editor.canvas.on('object:scaling', (e) => {
      const obj = e.target;

      if (obj) {
        const left = obj.left ?? 0;
        const top = obj.top ?? 0;
        const printableArea = getPrintableAreas().find(area => area.containsPoint(new fabric.Point(left, top)));
        if (obj.clipPath) {
          const oldClipPath = obj.clipPath;
          obj.clipPath = oldClipPath;
          editor.canvas.renderAll();
        } else if (printableArea && !(obj.type === 'textbox' || obj.type === 'text' || obj.type === 'i-text')) {
          const clipRect = getClippingRectForArea(printableArea);
          if (clipRect) {
            obj.clipPath = clipRect;
            editor.canvas.renderAll();
          }
        }
      }
    });

    editor.canvas.on('selection:created', (e) => {
      const selectedObject = e?.selected && e?.selected[0];
      if (selectedObject && (selectedObject.type === 'textbox' || selectedObject.type === 'text' || selectedObject.type === 'i-text')) {
        openTextEditDialog(selectedObject);
      }
    });
    editor.canvas.on('selection:updated', (e) => {
      const selectedObject = e?.selected && e?.selected[0];
      if (selectedObject && (selectedObject.type === 'textbox' || selectedObject.type === 'text' || selectedObject.type === 'i-text')) {
        openTextEditDialog(selectedObject);
      }
    });

    editor.canvas.on('selection:cleared', () => {
      setTextDialogOpen(false)
      setTextOptions(pre => ({ ...pre, text: "Edit Your text Added" }))
    });

    editor.canvas.renderAll();
  };


  /*   const getPrintableAreas = (): (fabric.Rect | fabric.Path)[] => {
      if (!editor || !editor.canvas) return [];
      const objects = editor.canvas.getObjects();
      return objects.filter(obj => (obj as any).id === 'printableArea') as (fabric.Rect | fabric.Path)[];
    };
    
     const getClippingRects = () => {
      const areas = getPrintableAreas();
      return areas.map((area,idx) => {
        if (area.type === 'rect') {
          const dime = area.getBoundingRect();
          return new fabric.Rect({
            left: dime.left,
            top: dime.top,
            width: dime.width,
            height: dime.height,
            absolutePositioned: true,
          });
        } else if (area.type === 'path') {
          const pathData = (area as fabric.Path).path;
          return new fabric.Path(pathData, {
            left: area.left,
            top: area.top,
            angle: area.angle,
            scaleX: area.scaleX,
            scaleY: area.scaleY,
            absolutePositioned: true,
          });
        }
        return null;
      }).filter(clip => clip !== null) as (fabric.Rect | fabric.Path)[];
    }; 
  
    const handleGroupClick = (group: fabric.Group) => {
      setGroupSelect(group);
      setModalOpen(true);
    };
  
   const attachEventListeners = () => {
      if (!editor || !editor.canvas) return;
  
      editor.canvas.forEachObject((obj: fabric.Object) => {
        obj.set({
          selectable: true,
          hasControls: true,
          hasBorders: true,
        });
  
        if ((obj as any).id === 'printableArea') {
          obj.set({
            selectable: false,
            evented: false,
            hasControls: false,
            hasBorders: false,
          });
        }
  
        if (obj.type === 'textbox' || obj.type === 'text' || obj.type === 'i-text') {
          const textObj = obj as fabric.IText;
          textObj.editable = true;
          textObj.evented = true;
  
          textObj.on('mousedown', (e) => {
            if (e.e.detail === 2) {
              textObj.enterEditing();
              editor.canvas.setActiveObject(textObj);
              editor.canvas.renderAll();
            }
          });
  
          textObj.on('editing:entered', () => {
            console.log('Entered editing mode for text:', textObj);
          });
  
          textObj.on('editing:exited', () => {
            console.log('Exited editing mode for text:', textObj);
          });
  
          textObj.set({
            lockMovementX: false,
            lockMovementY: false,
          });
  
          const clipRects = getClippingRects();
          if (clipRects.length > 0) {
            textObj.set('clipPath', new fabric.Group(clipRects, {
              absolutePositioned: true,
            }));
          }
        }
  
        if (obj.type === 'group') {
          const group = obj as fabric.Group;
  
          group.on('mousedown', () => handleGroupClick(group));
  
          group.on('scaling', () => {
            console.log('Group scaling event');
          });
  
          group.forEachObject((subObj: fabric.Object) => {
            if (subObj.type === 'text' && (subObj as fabric.Text).text && ((subObj as fabric.Text).text as string).includes('click to select image')) {
              attachImageUploadListener(subObj);
            }
          });
        }
      });
  
      editor.canvas.on('object:moving', (e) => {
        const obj = e.target;
        const clipRects = getClippingRects();
  
        if (obj && clipRects.length > 0) {
          obj.clipPath = new fabric.Group(clipRects, {
            absolutePositioned: true,
          });
          editor.canvas.renderAll();
        }
      });
  
      editor.canvas.on('object:scaling', (e) => {
        const obj = e.target;
        const clipRects = getClippingRects();
  
        if (obj && clipRects.length > 0) {
          obj.clipPath = new fabric.Group(clipRects, {
            absolutePositioned: true,
          });
          editor.canvas.renderAll();
        }
      });
  
      editor.canvas.renderAll();
    };;  */

  useEffect(() => {
    if (editor) {
      attachEventListeners();

      const handleKey = (e: KeyboardEvent) => {
        if (e.key === "Delete") {
          const object = editor.canvas.getActiveObject();
          if (object) editor.canvas.remove(object);
        }
      };

      window.addEventListener("keyup", handleKey);

      return () => {
        window.removeEventListener("keyup", handleKey);
      };
    }
  }, [editor]);


  useEffect(() => {
    if (editor && editor.canvas) {
      const fetchDesign = async () => {
        try {
          const response = await fetch(`${IMGURL}${designTemplate.templateUrl}`);
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const json = await response.json();
          handleJSONImport(json, true);
        } catch (error) {
          console.error('Error fetching design JSON:', error);
        }
      };

      fetchDesign();
    }
  }, [designTemplate, editor]);

  const handleJSONImport = (jsonData: any, screenCheck = false) => {
    if (!editor || !editor.canvas) return;

    try {
      editor.canvas.clear();
      setFixedTopLayerImg(false)

      editor.canvas.loadFromJSON(jsonData, async () => {
        editor.canvas.forEachObject((obj: fabric.Object) => {
          obj.set({
            selectable: true,
            hasControls: true,
            hasBorders: true,
          });

          if ((obj as any).id === 'printableArea') {
            obj.set({
              selectable: false,
              evented: false,
              hasControls: false,
              hasBorders: false,
            });
          }
          obj.off("mousedown");
          obj.off("scaling");
        });

        const areas = getPrintableAreas();
        setPrintableAreas(areas);

        attachEventListeners();
        if (screenCheck) {
          const editorwidth = editor.canvas.getWidth();
          const scaleRatio = (editorwidth + 4) / canvasWidth;
          //const editorHeight = editor.canvas.getHeight();
          //const scaleRatioH = editorHeight / canvasHeight;
          //const screenWidth = window.outerWidth;
          const screenWidth = window.innerWidth;
          if (screenWidth < canvasWidth) {
            editor.canvas.getObjects().forEach((obj) => {
              obj.scaleX = (obj.scaleX ?? 1) * scaleRatio;
              obj.scaleY = (obj.scaleY ?? 1) * scaleRatio;
              obj.left = (obj.left ?? 0) * scaleRatio;
              obj.top = (obj.top ?? 0) * scaleRatio;

              if (obj.type === 'textbox' || obj.type === 'text' || obj.type === 'i-text') {
                const textObj = obj as fabric.Textbox;
                textObj.fontSize = (textObj.fontSize ?? 12) * scaleRatio;
              }

              obj.setCoords();
            });
            const backgroundImage = editor.canvas.backgroundImage;
            if (backgroundImage && backgroundImage instanceof fabric.Image) {
              backgroundImage.scaleX = (backgroundImage.scaleX ?? 1) * scaleRatio;
              backgroundImage.scaleY = (backgroundImage.scaleY ?? 1) * scaleRatio;
            }
          }
        }
        editor.canvas.renderAll();
      });
    } catch (error) {
      console.error("Error parsing JSON:", error);
    }
  };

  const attachImageUploadListener = (obj: fabric.Object) => {
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.accept = "image/*";
    fileInput.style.display = "none";
    fileInput.onchange = (event) => handleImageUpload(event, obj);
    document.body.appendChild(fileInput);

    obj.on("mousedown", () => {
      fileInput.click(); // Trigger file input click when object is clicked
    });
  };

  const handleImageUpload = (event: Event, obj: fabric.Object) => {
    if (!editor || !editor.canvas) return;

    const input = event.target as HTMLInputElement;
    if (input.files && input.files[0]) {
      const file = input.files[0];
      const reader = new FileReader();
      reader.onload = (e) => {
        const imgElement = new Image();
        imgElement.src = e.target?.result as string;
        imgElement.onload = () => {
          const image = new fabric.Image(imgElement);
          image.scaleToWidth(obj.width ?? 0);
          image.scaleToHeight(obj.height ?? 0);
          image.set({
            left: obj.left,
            top: obj.top,
          });
          editor.canvas.remove(obj);
          editor.canvas.add(image);
          editor.canvas.renderAll();
        };
      };
      reader.readAsDataURL(file);
    }
  };

  const addText = async () => {
    if (!editor) return;
    const area = printableAreas[0]?.getBoundingRect();

    //if (!area) return;
    const textProps: fabric.ITextboxOptions = {
      left: area?.left ? area?.left + 1 : 50,
      top: area?.top ? area?.top + 1 : 50,
      width: 120,
      fontSize: textOptions.fontSize,
      fontFamily: textOptions.fontFamily,
      fill: textOptions.textColor,
      fontStyle: textOptions.fontStyle,
      fontWeight: textOptions.fontWeight,
    };
    const text = new fabric.Textbox(textOptions.text, textProps);
    editor.canvas.add(text);

    text.on('mousedblclick', () => {
      text.enterEditing();
      text.selectAll();
    });

    editor.canvas.setActiveObject(text);
    editor.canvas.renderAll();
  };

  const handleChangeSide = async () => {
    if (!editor) return;

    const json = await JSON.stringify(editor.canvas.toJSON(['id', 'attachEventListeners']));
    const base64Image = await editor.canvas.toDataURL();
    //const base64Image = "";
    await setCartCanvasSides((prev: any) => ({
      ...prev,
      "front": { previewUrl: base64Image, canvasUrl: json },
    }));

  };


  useEffect(() => {
    if (triggerAddCart) {
      handleAddCart();
      setTriggerAddCart(false); // Reset the trigger
    }
  }, [cartCanvasSides, triggerAddCart]);

  const handleAddCartBefore = async () => {
    const canvasObj = editor?.canvas.getObjects()
    const canvasObjFilText = canvasObj?.filter(obj=>(obj.type==="textbox" || obj.type === 'text' || obj.type === 'i-text') && !(obj as any).textValue)
    const canvasObjFilImg = canvasObj?.filter(obj=>obj.type==="group" && obj.visible ===true) 
   if(canvasObjFilImg?.length!>0){
    toast.error("Select the Images ")
    return;
   }else if(canvasObjFilText?.length!>0){
    toast.error("Change the text values")
    return;
   }else{
    await handleChangeSide();
    setTriggerAddCart(true);
   }

  };

  const handleAddCart = async () => {
    const totalQty = quantity
    if (totalQty <= 0) { toast.error("Count need to be atleast one"); return; }
    if (currentUser?.token) {
      try {
        const response = await AddItemCart({ customerId: currentUser.refId, productId: designTemplate.productId, qty: totalQty, orderDesign: JSON.stringify({ ...cartCanvasSides }), orderAttributes: JSON.stringify({ Color: mugColor }), readyToBuy: 1 });
        if ('error' in response) {
          console.error('error:', response.error);
          dispatch(addProductToCart({ ...designTemplate, orderDesign: { ...cartCanvasSides }, totalQty, cartId: uuidv4(), orderAttributes: { Color: mugColor } }))
          return;
        }
        const { success, result } = response?.data;

        console.log(success, result);

        /*  if (success && result)navigate("/cart") */
      } catch (error) {
        console.error('Error adding to cart:', error);
      }
    } else {
      dispatch(addProductToCart({ ...designTemplate, orderDesign: { ...cartCanvasSides }, totalQty, cartId: uuidv4(), orderAttributes: { Color: mugColor } }))
    }
    toast.success("Added to cart")
  };


  useEffect(() => {
    if (editor && editor.canvas) {
      const screenWidth = window.innerWidth;
      //const screenWidth = window.outerWidth;
      const newCanvasWidth = screenWidth < canvasWidth ? screenWidth - 12 : canvasWidth;
      const scaleRatio = newCanvasWidth / canvasWidth;
      const newCanvasHeight = canvasHeight * scaleRatio;

      editor.canvas.setWidth(newCanvasWidth - 4);
      editor.canvas.setHeight(newCanvasHeight - 4);
      editor.canvas.allowTouchScrolling = true;
      editor.canvas.preserveObjectStacking = true;
      editor.canvas.renderAll();
    }
  }, [editor, canvasWidth, canvasHeight]);

  /*   const handleCanvasReady = (canvas:any) => {
      onReady(canvas);
      canvas.setWidth(canvasWidth);
      canvas.setHeight(canvasHeight);
      canvas.renderAll();
    }; */

    
  return (
    <div className="bg-gray-50 rounded-lg shadow-lg md:px-2">
       <div className="flex flex-wrap gap-2  py-3">
            {hexColors?.length > 0 && (
              <div className="flex gap-2 flex-wrap items-center">
                <h4 className="font-medium text-sm">Color:</h4>
                {hexColors.map((color: string) => (
                  <Button
                    key={color}
                    value={color}
                    className="rounded-full shadow-lg w-8 h-8 flex items-center justify-center border border-gray-300"
                    onClick={() => setMugColor(color)}
                    style={{ backgroundColor: color }}
                  />
                ))}
              </div>
            )}
            <Button className="bg-orange-600 hover:bg-orange-700 text-white px-2 py-1 rounded-lg" size={"sm"} onClick={() => setSheetOpen(true)}>
              Upload Image
            </Button>
            <Button
              type="button"
              onClick={addText}
              size={"sm"}
              className="bg-orange-600 hover:bg-orange-700 text-white px-3 py-1 rounded-lg"
            >
              Add Text
            </Button>
        </div>
      <div className="flex sm:flex-wrap max-sm:flex-col w-full relative gap-4">
        <div className="flex flex-col max-sm:flex-row max-sm:w-full sm:max-w-[250px] gap-4 bg-white p-2 rounded-lg shadow-md">
          <Canvas
            frameloop="always"
            shadows
            dpr={[1, 2]}
            camera={{ position: [-1, 0.5, 1.5], fov: 65 }}
            style={{ maxHeight: '300px', maxWidth: '200px', minWidth: "150px", borderRadius: '8px' }}
          >
            <color attach="background" args={['#f0f0f0']} />
            <ambientLight intensity={0.6} />
            <spotLight intensity={0.1} angle={0.1} penumbra={1} position={[10, 15, 10]} castShadow />
            <Suspense fallback={null}>
              {visibleMug ? <MugModel mugColor={mugColor} /> : null}
              <Environment preset="city" />
            </Suspense>
            <OrbitControls />
          </Canvas>
        </div>
        <div className="max-w-full">
          {/* {(canvasHeight>0 && canvasWidth>0) && (
        <FabricJSCanvas
         // className={`border-2 border-gray-800  w-[800px] h-[300px]`}
          className={`border-2 border-gray-800  w-[${canvasWidth}px] h-[${canvasHeight}px]`}
          onReady={onReady}
        />
      )} */}

          {(canvasHeight > 0 && canvasWidth > 0) && (
            <div className='overflow-x-auto'>
              <FabricJSCanvas
                className={`border-2 border-gray-800 `}
                onReady={onReady}
              />
            </div>)}
        </div>

        {textDialogOpen && <TextEditDialog setTextOptions={setTextOptions} textOptions={textOptions} editor={editor} setTextDialogOpen={setTextDialogOpen} />}
      </div>
      <div className="flex gap-4 items-end mt-4">
        <div className="flex flex-col gap-4 w-full  my-2">
          <Separator className="bg-gray-400 h-[1px]" />
          <div className="flex items-center justify-between gap-4 flex-wrap">
            {designTemplate?.productName && (
              <div>
                <p className="font-semibold text-md ">{designTemplate?.productName}</p>
              </div>
            )}
            <div className="flex gap-4 items-center flex-wrap">
              {designTemplate?.priceINR && (
                <div className="flex items-center gap-1">
                  <p className="font-semibold text-md text-gray-400">Price:</p>
                  <p className=" text-md font-semibold">
                    {Intl.NumberFormat("en-us", { style: "currency", currency: "INR" }).format(designTemplate?.priceINR)}
                  </p>
                </div>
              )}
              <div className="flex border-[1px] border-gray-500 items-center gap-2 rounded-lg">
                <Button variant="ghost" size={"sm"} onClick={() => setQuantity((pre) => Math.max(1, pre - 1))} disabled={quantity === 1}>
                  <FiMinus />
                </Button>
                <input
                  type='numnber'
                  min={1}
                  className="w-16 border-none outline-none focus-visible:border-none focus-visible:ring-0 focus-visible:ring-transparent focus-visible:ring-offset-0 text-center"
                  value={quantity}
                  onChange={(e) => setQuantity(+e.target.value)}
                />
                <Button variant="ghost" size={"sm"} onClick={() => setQuantity((pre) => pre + 1)}>
                  <FaPlus />
                </Button>
              </div>
              <Button onClick={handleAddCartBefore} className="bg-blue-500 hover:bg-blue-700 text-white px-3 py-1 rounded-lg" size={"sm"}>
                Add To Cart
              </Button>
            </div>
          </div>
          {designTemplate?.productDescription && (
            <div>
              <p className="font-semibold text-md text-gray-400">Product Description:</p>
              <div className="mx-4 text-md">{parse(designTemplate?.productDescription)}</div>
            </div>
          )}
          <Separator className='bg-gray-200 h-[1px]' />
      <div className='flex justify-center flex-col items-center  gap-3 max-md:hidden'>
        <p className='font-bold text-xl'>Sample Image</p>
        <img   src={`${IMGURL}${designTemplate?.sampleImageUrl}`} alt="sampleImage" className='max-w-[400px] border-2 border-gray-400'  />
      </div>
        </div>
        {modalOpen && <SelectImageMug editor={editor} setModalOpen={setModalOpen} groupSelect={groupSelect} fixedTopLayerImg={fixedTopLayerImg} />}
        {sheetOpen && <SelectImagePrintableMug editor={editor} setSheetOpen={setSheetOpen} printableAreas={printableAreas} />}
      </div>
    </div>

  )
}

const fontStyle = [{ id: 1, icon: <TbLetterN  size={12}/>, sty: 'normal' }, { id: 2, icon: <GoItalic size={12} />, sty: 'Italic' }]
const fontWeight = [{ id: 1, icon: <TbLetterN size={12}/>, wgt: 'normal' }, { id: 2, icon: <ImBold  size={12}/>, wgt: 'bold ' }]
const textAlign = [
  { id: 1, icon: <FaAlignLeft size={12}/>, agn: 'left' },
  { id: 2, icon: <FaAlignCenter size={12}/>, agn: 'center' },
  { id: 3, icon: <FaAlignRight size={12}/>, agn: 'right' },
  { id: 4, icon: <FaAlignJustify size={12}/>, agn: 'justify' },
]
export function TextEditDialog({ editor, setTextOptions, textOptions, setTextDialogOpen }: any) {


  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTextOptions({ ...textOptions, text: e.target.value });
    updateActiveText({ text: e.target.value,textValue:e.target.value  });
  };

  const handleFontSizeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTextOptions({ ...textOptions, fontSize: parseInt(e.target.value) });
    updateActiveText({ fontSize: parseInt(e.target.value) });
  };
  const handleFontSizeChangeM = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setTextOptions({ ...textOptions, fontSize: parseInt(e.target.value) });
    updateActiveText({ fontSize: parseInt(e.target.value) });
  };

  const handleFontFamilyChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setTextOptions({ ...textOptions, fontFamily: e.target.value });
    updateActiveText({ fontFamily: e.target.value });
  };

  const handleTextColorChange = (color: string) => {
    setTextOptions({ ...textOptions, textColor: color });
    updateActiveText({ fill: color });
  };

  const handleTextAlignmentChange = (alignment: string) => {
    setTextOptions({ ...textOptions, textAlign: alignment });
    updateActiveText({ textAlign: alignment });
  };

  const handleFontStyleChange = (style: 'normal' | 'italic') => {
    setTextOptions({ ...textOptions, fontStyle: style });
    updateActiveText({ fontStyle: style });
  };

  const handleFontWeightChange = (weight: 'normal' | 'bold' | number) => {
    setTextOptions({ ...textOptions, fontWeight: weight });
    updateActiveText({ fontWeight: weight });
  };

  const handleUnderlineChange = () => {
    setTextOptions({ ...textOptions, underline: !textOptions.underline });
    updateActiveText({ underline: !textOptions.underline });
  };

  const updateActiveText = (options:any /* Partial<fabric.ITextboxOptions> */) => {
    if (!editor) return;
    //const activeObject = editor.canvas.getActiveObject() as fabric.Textbox;
    const activeObject = editor.canvas.getActiveObject() as fabric.Textbox & { textField?: string };
    if (activeObject && activeObject.type === 'textbox') {
      activeObject.set(options);
      editor.canvas.renderAll();
    }
  };

  const handleUnSelect = () => {
    editor.canvas.discardActiveObject();
    editor.canvas.renderAll();
    setTextDialogOpen(false);
    setTextOptions((prev: any) => ({ ...prev, text: "Edit Your text Added" }));
  };

  const deleteText = () => {
    if (editor && editor.canvas) {
      const activeObject = editor.canvas.getActiveObject();
      if (activeObject) {
        editor.canvas.remove(activeObject);
      }
    }
  };


  return (<>
  <div className={`relative max-sm:hidden  sm:max-w-full shadow-lg border-2 border-gray-600 z-10 bg-white p-2 rounded-lg`}>
      {/* <div className="flex justify-between gap-4 items-start">
        <p className='pb-2 text-gray-800 font-semibold '>Edit Text:</p>
        <Button onClick={handleUnSelect} variant={"link"} className='top-0 absolute right-0'><IoMdClose size={20} /></Button>
      </div> */}
         <div className="flex flex-wrap gap-4 items-center text-sm">
        <p className='pb-2 text-gray-800 font-semibold '>Edit Text:</p>
        <Input type="text" className='max-w-40 border-[1px] text-md font-medium border-gray-500 focus-visible:ring-transparent focus-visible:ring-0 ' value={textOptions.text} onChange={handleTextChange} />
        <Label className='flex items-center gap-1'>
          Size:
          <Input type="range" min="10" max="50" className='w-28' value={textOptions.fontSize} onChange={handleFontSizeChange} />
          <span className="font-semibold text-gray-700">{textOptions.fontSize}</span>
        </Label>
        <Label className='flex items-center gap-1'>
          Familiy:
          <select value={textOptions.fontFamily} onChange={handleFontFamilyChange} className='h-8 w-36 rounded-md border border-gray-400'>
            {fontFamiliesDefault.map((family: string) => <option value={family} key={family}>{family}</option>)}
          </select>
        </Label>
        <Label className='flex gap-1 items-center'>
          Color:
          <Input type="color" value={textOptions.textColor} onChange={(e) => handleTextColorChange(e.target.value)} className="w-[50px] h-9" />
        </Label>
        <Label className='flex gap-1 items-center'>
          Style:
          <div className='flex gap-1'>
            {fontStyle.map(style => (
              <Button key={style.id} variant={"link"} size={"sm"} onClick={() => handleFontStyleChange(style.sty as 'normal' | 'italic')}
                className={` rounded-md text-xs  ${textOptions.fontStyle === style.sty && 'bg-blue-500 text-white'}`}>
                {style.icon}
              </Button>
            ))}
            <Button variant={"link"} size={"sm"} className={`rounded-md text-xs p-1  ${textOptions.underline && 'bg-blue-500 text-white'}`}
              onClick={handleUnderlineChange}><FaUnderline size={12} /></Button>
          </div>
        </Label>
        <Label className='flex gap-1 items-center'>
          Align:
          <div>
            {textAlign.map(alignment => (
              <Button key={alignment.id} variant={"link"} onClick={() => handleTextAlignmentChange(alignment.agn)} className={` rounded-md text-xs ${textOptions.textAlign === alignment.agn && 'bg-blue-500 text-white'}`}>
                {alignment.icon}
              </Button>
            ))}
          </div>
        </Label>
        <Label className='flex gap-1 items-center'>
          Weight:
          <div className='flex gap-1 items-center'>
            {fontWeight.map(weight => (
              <Button key={weight.id} variant={"link"} size={"sm"} onClick={() => handleFontWeightChange(weight.wgt as 'normal' | 'bold' | number)} className={`rounded-md text-xs  ${textOptions.fontWeight === weight.wgt && 'bg-blue-500 text-white'}`}>
                {weight.icon}
              </Button>
            ))}
            <Input
              type="range"
              min="100"
              max="900"
              step="100"
              className='w-24'
              value={typeof textOptions.fontWeight === 'number' ? textOptions.fontWeight : 400}
              onChange={(e) => handleFontWeightChange(parseInt(e.target.value))}
            />
            <span className="font-semibold text-gray-700">
              {typeof textOptions.fontWeight === 'number' ? textOptions.fontWeight : '400'}
            </span>
          </div>
        </Label>
        <Button onClick={deleteText} variant={"ghost"}> <MdDelete size={20} color="red" /></Button>
        <Button onClick={handleUnSelect} variant={"link"} className='bottom-0-0 absolute right-0'><IoMdClose size={20} /></Button>
      </div>
      </div>
    {/* <div className={`relative max-sm:hidden mt-2  sm:max-w-full shadow-lg border-2 border-gray-600 z-10 bg-white p-2 rounded-lg`}>
      <div className="flex justify-between gap-4 items-start">
        <p className='pb-2 text-gray-800 font-semibold '>Edit Text:</p>
        <Button onClick={handleUnSelect} variant={"link"} className='top-0 absolute right-0'><IoMdClose size={20} /></Button>
      </div>
      <div className="flex flex-wrap gap-4 items-center">
        <Input type="text" className='max-w-60 border-[1px] text-md font-medium border-gray-500 focus-visible:ring-transparent focus-visible:ring-0 ' value={textOptions.text} onChange={handleTextChange} />

        <Label className='flex items-center gap-2'>
          Font Size:
          <Input type="range" min="10" max="50" className='w-28' value={textOptions.fontSize} onChange={handleFontSizeChange} />
          <span className="font-semibold text-gray-700">{textOptions.fontSize}</span>
        </Label>
        <Label>
          Font:
          <select value={textOptions.fontFamily} onChange={handleFontFamilyChange} className='h-8 w-36 rounded-md border border-gray-400'>
            {fontFamiliesDefault.map((family: string) => <option value={family} key={family}>{family}</option>)}
          </select>
        </Label>
        <Label className='flex gap-2 items-center'>
          Text Color:
          <Input type="color" value={textOptions.textColor} onChange={(e) => handleTextColorChange(e.target.value)} className="w-[50px] h-9" />
        </Label>
        <Label className='flex gap-2 items-center'>
          Font Style:
          <div className='flex gap-2'>
            {fontStyle.map(style => (
              <Button key={style.id} variant={"link"} size={"sm"} onClick={() => handleFontStyleChange(style.sty as 'normal' | 'italic')}
                className={` rounded-md ${textOptions.fontStyle === style.sty && 'bg-blue-500 text-white'}`}>
                {style.icon}
              </Button>
            ))}
            <Button variant={"link"} size={"sm"} className={`rounded-md ${textOptions.underline && 'bg-blue-500 text-white'}`}
              onClick={handleUnderlineChange}><FaUnderline /></Button>
          </div>
        </Label>
        <Label className='flex gap-2 items-center'>
          Align Text:
          <div>
            {textAlign.map(alignment => (
              <Button key={alignment.id} variant={"link"} onClick={() => handleTextAlignmentChange(alignment.agn)} className={` rounded-md ${textOptions.textAlign === alignment.agn && 'bg-blue-500 text-white'}`}>
                {alignment.icon}
              </Button>
            ))}
          </div>
        </Label>
        <Label className='flex gap-2 items-center'>
          Font Weight:
          <div className='flex gap-2'>
            {fontWeight.map(weight => (
              <Button key={weight.id} variant={"link"} size={"sm"} onClick={() => handleFontWeightChange(weight.wgt as 'normal' | 'bold' | number)} className={`rounded-md ${textOptions.fontWeight === weight.wgt && 'bg-blue-500 text-white'}`}>
                {weight.icon}
              </Button>
            ))}
            <Input
              type="range"
              min="100"
              max="900"
              step="100"
              className='w-28'
              value={typeof textOptions.fontWeight === 'number' ? textOptions.fontWeight : 400}
              onChange={(e) => handleFontWeightChange(parseInt(e.target.value))}
            />
            <span className="font-semibold text-gray-700">
              {typeof textOptions.fontWeight === 'number' ? textOptions.fontWeight : '400'}
            </span>
          </div>
        </Label>
        <Button onClick={deleteText} variant={"ghost"}> <MdDelete size={20} color="red" /></Button>

      </div>
    </div> */}
    <div className="fixed sm:hidden bottom-0 left-0 right-0 bg-white p-4 px-2 shadow-lg border-t-2 border-gray-600 z-10 flex items-center justify-between">
      <Button onClick={handleUnSelect} variant={"ghost"} className='p-0 px-2' ><IoMdClose size={18} /></Button>

      <div className="flex-1 flex overflow-x-auto gap-4 items-center justify-center whitespace-nowrap" >
        <div className="flex gap-4 min-w-full">
          <Input type="text" className="min-w-[150px] border-[1px] text-md font-medium border-gray-500 focus-visible:ring-transparent focus-visible:ring-0" value={textOptions.text} onChange={handleTextChange} />

          <Label className="flex items-center gap-2">
            Font Size:
            <select
              value={textOptions.fontSize}
              onChange={handleFontSizeChangeM}
              className="h-8 w-20 rounded-md border border-gray-400"
            >
              {Array.from({ length: 10 }, (_, i) => i + 10).map((size) => (
                <option value={size} key={size}>
                  {size}
                </option>
              ))}
              {Array.from({ length: 16 }, (_, i) => 20 + i * 2).map((size) => (
                <option value={size} key={size}>
                  {size}
                </option>
              ))}
            </select>
          </Label>

          <Label className="flex items-center gap-2">
            Font:
            <select value={textOptions.fontFamily} onChange={handleFontFamilyChange} className="h-8 w-36 rounded-md border border-gray-400">
              {fontFamiliesDefault.map((family: string) => <option value={family} key={family}>{family}</option>)}
            </select>
          </Label>

          <Label className="flex gap-2 items-center">
            Text Color:
            <Input type="color" value={textOptions.textColor} onChange={(e) => handleTextColorChange(e.target.value)} className="w-[50px] h-9" />
          </Label>

          <Label className="flex gap-2 items-center">
            Font Style:
            <div className="flex gap-2">
              {fontStyle.map(style => (
                <Button key={style.id} variant={"link"} size={"sm"} onClick={() => handleFontStyleChange(style.sty as 'normal' | 'italic')}
                  className={` rounded-md ${textOptions.fontStyle === style.sty && 'bg-blue-500 text-white'}`}>
                  {style.icon}
                </Button>
              ))}
              <Button variant={"link"} size={"sm"} className={`rounded-md ${textOptions.underline && 'bg-blue-500 text-white'}`}
                onClick={handleUnderlineChange}><FaUnderline /></Button>
            </div>
          </Label>

          <Label className="flex gap-2 items-center">
            Align Text:
            <div className="flex gap-1">
              {textAlign.map(alignment => (
                <Button key={alignment.id} variant={"link"} onClick={() => handleTextAlignmentChange(alignment.agn)} className={` rounded-md ${textOptions.textAlign === alignment.agn && 'bg-blue-500 text-white'}`}>
                  {alignment.icon}
                </Button>
              ))}
            </div>
          </Label>

          <Label className="flex gap-2 items-center">
            Font Weight:
            <div className="flex gap-2">
              {fontWeight.map(weight => (
                <Button key={weight.id} variant={"link"} size={"sm"} onClick={() => handleFontWeightChange(weight.wgt as 'normal' | 'bold' | number)} className={`rounded-md ${textOptions.fontWeight === weight.wgt && 'bg-blue-500 text-white'}`}>
                  {weight.icon}
                </Button>
              ))}

            </div>
          </Label>
          <Button onClick={deleteText} variant={"ghost"}> <MdDelete size={20} color="red" /></Button>

        </div>
      </div>

    </div>

  </>);
}

export default MugDesignCanvas;


