import { PaperTypeItem } from "@/react-app-env";

export const hexColors = [
  "#FF0000", // Red
  "#00FF00", // Green
  "#0000FF", // Blue
  "#FFFF00", // Yellow
  "#000000", // Black
  "#FFFFFF", // White
  "#FFC0CB", // Pink
  "#A52A2A", // Brown
];

export const NavLinksH = [
  {
    "id": 0,
    "categoryName": "All",
    "route": "all",
    "thumbnail": "/assets/bc/b8.png"
},
{
    "id": 1,
    "categoryName": "T Shirts",
    "route": "tshirts",
    "thumbnail": "/assets/bc/b1.png"
},
{
    "id": 2,
    "categoryName": "Mugs",
    "route": "mugs",
    "thumbnail": "/assets/bc/b2.png"
},
{
  "id": 4,
  "categoryName": "Cushions",
  "route": "cushions",
  "thumbnail": "/assets/bc/b4.png"
},
{
  "id": 11,
  "categoryName": "Office Stationary",
  "route": "officestationary",
  "thumbnail": "/assets/bc/b3.png"
},
{
    "id": 15,
    "categoryName": "Fridge Magnets",
    "route": "fridgemagnets",
    "thumbnail": "/assets/bc/b6.png"
},
{
    "id": 21,
    "categoryName": "Key Chains",
    "route": "keychains",
    "thumbnail": "/assets/bc/b7.png"
},
{
    "id": 22,
    "categoryName": "Mouse Pads",
    "route": "mousepads",
    "thumbnail": "/assets/bc/b8.png"
},
]

export const tshirts = [
  {id:1,
    thumbnail:"/assets/tshirts/ts.png",
    category:"tshirts"
  },
  {id:2,
    thumbnail:"/assets/tshirts/11.jpg",
    category:"tshirts"
  },
  {id:6,
    thumbnail:"/assets/mobilecases/m1.jpeg",
    category:"mobilecovers"
  },
  {id:7,
    thumbnail:"/assets/mobilecases/m2.jpg",
    category:"mobilecovers"
  },
  {id:8,
    thumbnail:"/assets/caps/c1.png",
    category:"caps"
  },
  {id:3,
    thumbnail:"/assets/mugs/mg.jpeg",
    sheet:"/assets/mugs/mugbg.jpg",
    category:"mugs"
  },
  {id:4,
    thumbnail:"/assets/mugs/mug.png",
    sheet:"/assets/mugs/mug1.png",
    category:"mugs"
  },
  {id:5,
    thumbnail:"/assets/mugs/mug2.png",
    sheet:"/assets/mugs/mug2.png",
    category:"mugs"
  },
  {id:9,
    thumbnail:"/assets/visitingcards/v1.jpeg",
    category:"visitingcards"
  },
  {id:10,
    thumbnail:"/assets/visitingcards/v2.jpeg",
    category:"visitingcards"
  },
  {id:11,
    thumbnail:"/assets/visitingcards/v3.png",
    category:"visitingcards"
  },
  {id:12,
    thumbnail:"/assets/letter/l1.png",
    category:"letterheads"
  },
  {id:13,
    thumbnail:"/assets/letter/l2.png",
    category:"letterheads"
  },
  {id:14,
    thumbnail:"/assets/bill/b1.png",
    category:"billbooks"
  },
  {id:15,
    thumbnail:"/assets/bill/b2.png",
    category:"billbooks"
  },
  {id:16,
    thumbnail:"/assets/bottle/b2.png",
    category:"waterbottles"
  },
  
]

export const fontFamiliesDefault = [
  'Arial',
  'Helvetica',
  'Times New Roman',
  'Georgia',
  'Verdana',
  'Courier New',
  'Lucida Console',
  'Impact',
  'Palatino Linotype',
  'Tahoma',
]

export const subHeaderLinks = [
  {
    label: 'HOME',
    route: '/',
  },
  {
    label: 'ELECTRONIC',
    route: '/category/ELECTRONIC',
    subroutes:[{
      label: 'smartphones',
      route: '/category/smartphones',
    },{
      label: 'laptops',
      route: '/category/laptops',
    },]
  },
  {
    label: 'CLOTHING',
    route: '/category/3',
    subroutes:[{
      label: 'tops',
      route: '/category/tops',
    },
    {
      label: 'womens-dresses',
      route: '/category/womens-dresses',
    },
    {
      label: 'mens-shirts',
      route: '/category/mens-shirts',
    },
  ]
  },
 
  {
    label: 'GROCERIES',
    route: '/category/groceries',
  },
  {
    label: 'BEAUTY&FASHION',
    route: '/category/1',
    subroutes:[{
      label: 'skincare',
      route: '/category/skincare',
    },
    {
      label: 'fragrances',
      route: '/category/fragrances',
    },
    {
      label: 'womens-shoes',
      route: '/category/womens-shoes',
    },
    {
      label: 'mens-shoes',
      route: '/category/mens-shoes',
    },
    {
      label: 'mens-watches',
      route: '/category/mens-watches',
    },
    {
      label: 'womens-watches',
      route: '/category/womens-watches',
    },
    {
      label: 'womens-bags',
      route: '/category/womens-bags',
    },
    {
      label: 'womens-jewellery',
      route: '/category/womens-jewellery',
    },
    {
      label: 'sunglasses',
      route: '/category/sunglasses',
    },
  ]
  },
  {
    label: 'AUTOMOBILE',
    route: '/category/2000',
    subroutes:[{
      label: 'automotive',
      route: '/category/automotive',
    },{
      label: 'motorcycle',
      route: '/category/motorcycle',
    }]
  },
  {
    label: 'HOME&FURNITURE',
    route: 'home-decoration',
    subroutes:[{
      label: 'home-decoration',
      route: '/category/home-decoration',
    },{
      label: 'lighting',
      route: '/category/lighting',
    },{
      label: 'furniture',
      route: '/category/furniture',
    }]
  },
]


export const shopByPriceCollection = [
  {
    id: 1,
    url: "/assets/pay/m1.jpeg",
    priceINR: 10000,
    priceUSD:50
  },
  {
    id: 2,
    url: "/assets/pay/m2.webp",
    priceINR: 15000,
    priceUSD:100
  },
  {
    id: 3,
    url: "/assets/pay/m1.jpeg",
    priceINR: 20000,
    priceUSD:150
  },
  {
    id: 4,
    url: "/assets/pay/m4.jpg",
    priceINR: 25000,
    priceUSD:200
  },
  {
    id: 5,
    url: "/assets/pay/t1.webp",
    priceINR: 10000,
    priceUSD:250
  },
  {
    id: 6,
    url: "/assets/pay/t2.jpeg",
    priceINR: 20000,
    priceUSD:300
  },
  {
    id: 7,
    url: "/assets/pay/t3.jpg",
    priceINR: 30000,
    priceUSD:350
  },
  {
    id: 8,
    url: "/assets/pay/t4.jpg",
    priceINR: 40000,
    priceUSD:350
  },
  {
    id: 9,
    url: "/assets/pay/d1.webp",
    priceINR: 2000,
    priceUSD:350
  },
  {
    id: 10,
    url: "/assets/pay/d2.jpg",
    priceINR: 4000,
    priceUSD:350
  },
  {
    id: 11,
    url: "/assets/pay/d3.webp",
    priceINR: 6000,
    priceUSD:350
  },
  {
    id: 12,
    url: "/assets/pay/d4.webp",
    priceINR: 8000,
    priceUSD:350
  },
];

export const userPolicy = [
  { name: "Privacy Policy", route: "/privacy-policy" },
  { name: "Terms & Conditions", route: "/terms-and-conditions" },
  { name: "Return Policy", route: "/return-policy" },
  { name: "Shipping Policy", route: "/shipping-policy" }
];

export const letUsHelpYou = [
  { path: "/stores", name: "Stores" },
  { path: "/help-faq", name: "Help/FAQ" },
  { path: "/enquiry", name: "Franchise Enquiry" },
  { path: "/about-us", name: "About us" }
];

export const weavesData = [
  {
    categoryId: 2,
    routeUrl: '/banaras',
    imgSrc: '/assets/categories/BanarasSaree.jpg',
    name: 'BANARAS SAREES',
  },
  {
    categoryId: 3,
    routeUrl: '/cotton',
    imgSrc: '/assets/categories/CottonSaree.jpg',
    name: 'COTTON SAREES',
  },
  {
    categoryId: 4,
    routeUrl: '/fancy',
    imgSrc: '/assets/categories/FancySaree.jpeg',
    name: 'FANCY SAREES',
  },
  {
    categoryId: 1,
    routeUrl: '/silk',
    imgSrc: '/assets/categories/SilkSaree.jpeg',
    name: 'PURE SILK SAREES',
  },
];

export const templateDefaultValues = {
  categoryId: 1,
  subcategoryId: 1,
  templateUrl:'',
  templateSample:"",
}


export const productDefaultValues = {
  productName: '',
  productDescription: '',
  thumbnail: '',
  priceINR: 1,
  categoryId: 1,
  subcategoryId: 1,
  discount: 0,
  priceUSD: 1,
  soldQuantity: 1,

}

export const indianCourierServices = [
  { id: 1, name: "Blue Dart Express Ltd." },
  { id: 2, name: "DTDC Express Limited" },
  { id: 3, name: "Professional Courier Network Limited" },
  { id: 4, name: "Delhivery Pvt Ltd" },
  { id: 5, name: "FedEx Express" },
  { id: 6, name: "Aramex India Pvt Ltd" },
  { id: 7, name: "Gati Ltd" },
  { id: 8, name: "India Post (India Post)" },
  { id: 9, name: "Ecom Express Pvt Ltd" },
  { id: 10, name: "First Flight Couriers Ltd" }
];


export const orderStatuses = [
  { id: 1, name: 'In Progress' },
  { id: 2, name: 'Dispatched' },
  { id: 3, name: 'Delivered' },
  { id: 4, name: 'Cancelled - Pending Refund' },
  { id: 5, name: 'Cancelled - Refund Completed' },
];

export const cancelledStatuses = [
  { id: 1, name: 'Cancelled - Pending Refund' },
  { id: 2, name: 'Cancelled - Refund Completed' },
];



export const privacyConstants = [
  {
    title: "3.Data Security",
    items: 
      "We take reasonable measures to protect your personal information from loss, theft, misuse, unauthorized access, disclosure, alteration, and destruction. However, please be aware that no method of transmission over the internet or electronic storage is 100% secure."
    
  },
  {
    title: "4.Your Rights",
    items: 
      "You have the right to access the personal information we hold about you, correct inaccuracies, and request the deletion of your personal information. To exercise these rights, please contact us at manager@chennapatnampattucheeralaangadi.com"
    
  },
  {
    title: "5.Changes to This Policy",
    items: 
      "We may update this privacy policy from time to time. The updated version will be indicated by an updated 'Last updated' date. We encourage you to review this policy periodically."
  
  },
  {
    title: "6.Contact Us",
    items: 
      "For any questions, concerns, or requests regarding your privacy, please contact us at manager@chennapatnampattucheeralaangadi.com"
    
  }
];


export const returnPolicy = [
  "The returnable product(s) must be in unused and undamaged condition. The price, product tags, and original receipt/invoice should also be intact.",
  "The customers should mention his/her name, order number, and mobile number, on the top of the packaging in which the product(s) is couriered by the earlier mentioned procedure.",
  "The product(s), the customer wants to return, must be in saleable condition, original folds and packaging should be retained.",
  "Our QC team has the sole right to decide whether the product(s) is eligible for return/exchange.",
  "The customers are supposed to make the return request with the images of the defective product(s) within two days from the day of receiving the product(s) by contacting our customer support through email/phone call.",
  "Any product(s) that don't justify our quality check will be returned to the customer as per our policies. And the shipment charges have to be borne by the customer.",
  "We allow the return of the product(s) only if they have a manufacturing defect.",
  "Once received, the product(s) that are requested for exchange/return will be thoroughly inspected by our quality check team before deciding on further proceedings.",
  "Once the product(s) is accepted by our team, the customers are requested to send the eligible product(s) to our designated address within 7 business days.",
  "If product purchased online, the exchange of the product(s) will only be online and no in-store procedures are allowed.",
  "On receipt of the product(s) in the manner described above, if we find out in our sole discretion that the product(s) is ineligible for return, we will contact such customer through their registered email-ID or phone number with further instructions."
];

export const productSpecifications = [
  "The customers who are preferring to buy items with beaded embroidery and sequins must be aware of the fact that these shiny embellishments tend to come off, easily, even with the best of handling and care, which is an unavoidable problem. So, before ordering such item(s) please understand and make a point of these things.",
  "The product specifications and details (colour, weight, size, embellishments, and handwork) that are mentioned on the Chennapatnam Pattu Cheerala Angadi website are not exact but only the approximate values. Any return request that is raised based on these attributes will not be accepted.",
  "The customers are requested to understand that there might be a slight variation of around 20 percent in the colour, which occurs due to various reasons while placing the order from our website. Nevertheless, we strive to furnish the exact colour of the product(s) on your computer’s screen. The variations usually show up because of the variations in the resolutions, monitors, browsers used, operating system, etc.",
  "The description mentioned along with the product(s) on our website is left to the creativity of content writers. Any purchased product(s) requested for return/exchange based on these will not be considered.",
  "The garments usually tend to reflect in different shades under different lights, which is why the variation in the shade of colour tends to occur in the images on the website and is considered normal.",
  "If the customer(s) feel to check the product(s) before the purchase, then call us at +91-8885252277"
];

export const cancellationPolicy = [
  "If the customer wishes to cancel the complete or a part of the order, before the order is approved, an email with the subject line - should be sent to manager@chennapatnampattucheeralaangadi.com for the cancellation request.",
  "If there are any technical/stock issues for a placed order, Chennapatnam Pattu Cheerala Angadi has the right to cancel the order without any notification and will refund the amount to the customer.",
  "The customers will not have an option to cancel any order placed from our website, once it is either processed or approved by our team.",
  "If the order is approved, by the time the cancellation request is raised by the customer, then the requested request will not be processed."
];

export const refundPolicy = [
  "The refund for the orders paid through Net Banking/Debit Cards/Credit Cards/UPI, amount would be reflected in the source account in 7-14 business days.",
  "For all the COD orders, the refund will be in the form of vouchers, which can be redeemed on the next purchase.",
  "To know the status of the refund, the customer(s) can either call us or email us, to our customer support desk. We will promptly respond to all our customers and their concerns and queries in any regard.",
  "Once the received product(s), which is requested for return aligns with our exchange policy, we may accomplish the customer’s refund processing within 7-10 working days from the receipt of the product(s) from the customer in this regard."
];

export const companyDeatils=[
  {id:1,
    name:"About US",
    route:"about-us"
  },
  {id:2,
    name:"Terms & Conditions",
     route:"terms-conditions"
  },
  {id:3,
    name:"Privacy Policy",
     route:"privacy-policy"
  },
]
//export const serialNotype = [{ id: 1, type: "1 and continuous" }, { id: 2, type: "001 and continuous" }, { id: 3, type: "No Numbering" }, { id: 4, type: "Mention Below" }]

// export const PaperSizes = [{ id: 1, paper: "A4 Size Paper(Large)", ePrice: 100 }, { id: 2, paper: "A5 Size Paper(Small)", ePrice: 0 }]

// export const PaperQuality = [{ id: 1, quality: "70 GSM Maplitho Paper", ePrice: 0 }, { id: 2, quality: "90 GSM Maplitho Paper", ePrice: 20 }, { id: 3, quality: "100 GSM Maplitho Paper", ePrice: 30 }, { id: 4, quality: "120 GSM Maplitho Paper", ePrice: 40 }]

// export const PaperTypeFixed: PaperTypeItem[] = [
//   {
//     id: 1,
//     type: "All Originals",
//     enabled:true,
//     ePrice:0,
//     contains: [
//       { id: 1, contain: "100 Originals" ,enabled:true, ePrice:0},
//       { id: 2, contain: "200 Originals",enabled:true, ePrice:100 }
//     ],
//     colors: [{ id: 1, color: "White",enabled:true }]
//   },
//   {
//     id: 2,
//     type: "Duplicate",
//     enabled:true,
//     ePrice:100,
//     contains: [
//       { id: 1, contain: "50 Originals + 50 Duplicates",enabled:true, ePrice:0 },
//       { id: 2, contain: "100 Originals + 100 Duplicates",enabled:true, ePrice:200 }
//     ],
//     colors: [
//       { id: 1, color: "White + White",enabled:true },
//       { id: 2, color: "White + Pink",enabled:true },
//       { id: 3, color: "White + Yellow" ,enabled:true}
//     ]
//   },
//   {
//     id: 3,
//     type: "Triplicate",
//     enabled:true,
//     ePrice:150,
//     contains: [
//       { id: 1, contain: "50 Originals + 50 Duplicates +50 Triplicates",enabled:true, ePrice:0 }
//     ],
//     colors: [
//       { id: 1, color: "White + yellow + Pink",enabled:true },
//       { id: 2, color: "White + Pink + Yellow" ,enabled:true}
//     ]
//   }
// ];

