import { NavLink, useNavigate } from "react-router-dom"
import { FaShoppingCart } from "react-icons/fa";
import { FaRegUserCircle } from "react-icons/fa";
import {
  Sheet,
  SheetContent,
  SheetTrigger,
} from "../../../components/ui/sheet"
import { Button } from "../../../components/ui/button";
import { MdAccountCircle } from "react-icons/md";
import { Separator } from "../../../components/ui/separator";
import { RootState } from "../../../redux/store";
import { useGetCartCountByCustomerQuery } from "../../../redux/slice/CartApiSlice";
import { useSelector } from "react-redux";

const HeaderIcons = () => {
  const currentUser = JSON.parse(localStorage.getItem("pfuser")!);
  const { cartItems } = useSelector((state: RootState) => state.counterCart);
  const { auth } = useSelector((state: RootState) => state.authCon)
  const { data: cartItemsCountApi } = useGetCartCountByCustomerQuery(currentUser?.refId, { skip: !currentUser?.refId,  refetchOnFocus: true, refetchOnMountOrArgChange: true })
  const cartItemsCount = cartItemsCountApi?.result?.cartCount > 0 ? cartItemsCountApi?.result?.cartCount : cartItems.reduce((acc, item) => acc + item.totalQty, 0);


  return (
    <div className="flex gap-6 items-center ">
      <div className="relative">
        <NavLink to="/cart" className=" hover:text-blue-600">
          <FaShoppingCart size={24} className="text-blue-800"/>{" "}
          {cartItemsCount > 0&& (
            <div className={`absolute ${cartItemsCount >= 100?"top-[-20px] right-[-15px]":"top-[-15px] right-[-10px]"} shadow-xl headermenu text-white ${cartItemsCount >= 100?"w-8 h-8":"w-6 h-6"}  flex justify-center items-center rounded-full text-xs`}>
              <p >
                {cartItemsCount}
              </p>
            </div>
          )}
        </NavLink>
      </div>
      {!currentUser || !currentUser?.token ? (
        <NavLink to="/signin" className="hover:text-blue-600">
          <MdAccountCircle size={24} className="text-blue-800"/>
        </NavLink>
      ) : (
        <UserIcon />
      )}
    </div>
  )
}

export const UserIcon = () => {
  const navigate = useNavigate()
  const currentUser = JSON.parse(localStorage.getItem("pfuser")!);
  const handleSignout = async () => {
    localStorage.removeItem("pfuser")
    navigate("/signin");
    window.location.reload();
  }

  return (
    <nav className="sticky top-0">
      <Sheet key="right" >
        <SheetTrigger className="align-middle hover:text-blue-800">
          <FaRegUserCircle size={24} />
        </SheetTrigger>
        <SheetContent className="flex flex-col gap-6 bg-white" side="right">
          <p className="text-lg font-semibold">Customer Details</p>
          <Separator className="border border-gray-100" />
          <div className="flex flex-col gap-4 text-md font-semibold">
            {currentUser?.token && (<>
              <div className="flex justify-between">
                <p>Fullname:</p>
                <p>{currentUser?.fullName}</p>
              </div>
              <div className="flex justify-between">
                <p>Mobile:</p>
                <p>{currentUser?.userName}</p>
              </div>
              <Separator className="border border-gray-100" />
              <NavLink to="/myorders" className="text-md font-bold text-green-800">My Orders</NavLink>
              <Separator className="border border-gray-100" />
            </>)}
            <div className="flex justify-between mt-8">
              <NavLink to="/userdetails">Edit Details</NavLink>
              <Button onClick={handleSignout}>SignOut</Button>
            </div>
          </div>
        </SheetContent>
      </Sheet>
    </nav>
  );
};
export default HeaderIcons
