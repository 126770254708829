import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { fabric } from 'fabric';
import { FabricJSCanvas, useFabricJSEditor } from 'fabricjs-react';
import { Button } from '../../../../../components/ui/button';
import { fontFamiliesDefault } from '../../../../../constants';
import { Label } from '../../../../../components/ui/label';
import { Input } from '../../../../../components/ui/input';
import { Separator } from '../../../../../components/ui/separator';
import { IMGURL } from '../../../../../constants/appConfig';
import { useNavigate } from 'react-router-dom';
import PrintableAreaShapes from './PrintableAreaShapes';
import { useGetTransparentImagesAMutation } from "../../../../../redux/slice/admin/ALogoApiSlice"
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
} from "../../../../../components/ui/sheet"
import { RadioGroup, RadioGroupItem } from "../../../../../components/ui/radio-group"
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../../../../components/ui/dialog"
import { useGetProductAttributesByCatSubCatMutation } from '../../../../../redux/slice/admin/AAttributeApiSlice';
import { FaArrowRotateRight } from 'react-icons/fa6';

/* type CreateTemplateProps = {
  canvasDimensions:{width:number,height:number},
  value:string,
  onChangeHandler: () => void;
} */
type Side = "front" | "back" | "left" | "right";

interface SideState {
  previewUrl: string;
  canvasUrl: string;
}

fabric.Rect.prototype.toObject = (function (toObject) {
  return function (this: fabric.Rect, propertiesToInclude?: string[]) {
    return toObject.call(this, propertiesToInclude?.concat('id') || ['id']);
  };
})(fabric.Rect.prototype.toObject);

fabric.Textbox.prototype.toObject = (function (toObject) {
  return function (this: fabric.Textbox, propertiesToInclude?: string[]) {
    // Include 'textField' property along with other properties
    return toObject.call(this, propertiesToInclude?.concat('textField') || ['textField']);
  };
})(fabric.Textbox.prototype.toObject);

fabric.Textbox.prototype.toObject = (function (toObject) {
  return function (this: fabric.Textbox, propertiesToInclude?: string[]) {
    // Include 'textField' property along with other properties
    return toObject.call(this, propertiesToInclude?.concat('textValue') || ['textValue']);
  };
})(fabric.Textbox.prototype.toObject);



fabric.Image.prototype.toObject = (function (toObject) {
  return function (this: fabric.Image, propertiesToInclude?: string[]) {
    return toObject.call(this, propertiesToInclude?.concat('uuid') || ['uuid']);
  };
})(fabric.Image.prototype.toObject);


fabric.Group.prototype.toObject = (function (toObject) {
  return function (this: fabric.Group, propertiesToInclude?: string[]) {
    return toObject.call(this, propertiesToInclude?.concat('uuid') || ['uuid']);
  };
})(fabric.Group.prototype.toObject);



const CreateTemplate = ({ canvasDimensions, setCanvasSides, categoryId, subCategoryId, onChangeHandler, templateEditJsonData, onSubmit, setTemplatePreviewUrl, type }: any) => {
  const { editor, onReady } = useFabricJSEditor();
  // const [uploadedImage, setUploadedImage] = useState<fabric.Image | null>(null);//Image upload file
  const [inputArea, setInputArea] = useState<fabric.Group | null>(null);  //select Image input area
  const [selectedRect, setSelectedRect] = useState<fabric.Rect | null>(null);
  const navigate = useNavigate()
  const [selectedLine, setSelectedLine] = useState<fabric.Object | null>(null);
  const [lineProperties, setLineProperties] = useState({ stroke: '#000000', width: 1 });
  const [strokeDetRec, setStrokedetRec] = useState({ stroke: '#ff0000', width: .5, fill: '#ffffff' });
  const [textOptions, setTextOptions] = useState({
    fontSize: 20,
    fontFamily: 'Arial',
    textColor: '#000000',
    fontStyle: 'normal' as 'normal' | 'italic' | 'oblique' | undefined,
    fontWeight: 'normal' as 'normal' | 'bold' | 'bolder' | 'lighter' | number | undefined,
    underline: false,
    textAlign: 'left',
  });
  const [sides, setSides] = useState<Record<Side, SideState>>({
    front: { previewUrl: "", canvasUrl: "" },
    back: { previewUrl: "", canvasUrl: "" },
    left: { previewUrl: "", canvasUrl: "" },
    right: { previewUrl: "", canvasUrl: "" },
  });
  const [side, setSide] = useState<Side>("front");
  const [saveT, setSavet] = useState<boolean>(false);
  const canvasRef = useRef(null);
  const [openSheetTemplate, setOpenSheetTemplate] = useState<boolean>(false)
  const [getProductAtt, { data: fixedSidesAdmin }] = useGetProductAttributesByCatSubCatMutation()

  function blobToBase64(blob: Blob) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  }

  useEffect(() => {
    getProductAtt({ catId: categoryId, subcatId: subCategoryId, attributeName: "Sides" })
  }, [getProductAtt, categoryId, subCategoryId])

  useEffect(() => {
    const fetchData = async (side: Side, CUrl: string, PUrl: string) => {
      try {
        const response = await fetch(CUrl);
        const response1 = await fetch(PUrl);
        const blob = await response1.blob();

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const json = await response.json();
        const base64String = await blobToBase64(blob);

        if (json && json.objects) {
          const stringdata = await JSON.stringify(json);
          setSides((pre) => ({
            ...pre,
            [side]: { canvasUrl: stringdata, previewUrl: base64String },
          }));
        } else {
          console.error('Invalid JSON format:', json);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    if (type === "Edit" && editor && editor.canvas) {
      editor.canvas.clear();
      const sidesToFetch = Object.keys(templateEditJsonData) as Side[];

      sidesToFetch.forEach((side) => {
        const CUrl = `${IMGURL}${templateEditJsonData[side].canvasUrl}`;
        const PUrl = `${IMGURL}${templateEditJsonData[side].previewUrl}`;
        fetchData(side, CUrl, PUrl);
      });
    }
  }, [type, templateEditJsonData, editor]);

  useEffect(() => {
    if (type === "Edit" && editor && editor.canvas) {
      editor.canvas.clear();

      const callback = () => {
        console.log('Canvas loaded from JSON');
        editor.canvas.renderAll(); // Ensure the canvas is rendered after loading
      };

      const fetchData = async () => {
        try {
          const url = `${IMGURL}${templateEditJsonData.front.canvasUrl}`;
          const response = await fetch(url);
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const json = await response.json();
          // console.log('Fetched JSON:', json);

          // Validate the JSON structure before loading it into the canvas
          if (json && json.objects) {
            editor.canvas.loadFromJSON(json, callback /* , (error: any) => {
              if (error) {
                console.error('Error loading JSON into canvas:', error);
              }} */);

          } else {
            console.error('Invalid JSON format:', json);
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };

      fetchData();
    }
  }, [type, templateEditJsonData, editor]);

  const handleChange = async (value: Side) => {
    if (!editor) return;

    // Save the current side's state
    const json = await JSON.stringify(editor.canvas.toJSON(['id', 'attachEventListeners']));
    const base64Image = await editor.canvas.toDataURL();
    await setSides((prev: any) => ({
      ...prev,
      [side]: { previewUrl: base64Image, canvasUrl: json },
    }));

    // Switch to the new side
    setSide(value);

    // Load the new side's state
    const selectedSide = sides[value];
    if (selectedSide.canvasUrl) {
      await editor.canvas.loadFromJSON(selectedSide.canvasUrl, editor.canvas.renderAll.bind(editor.canvas));
    } else {
      await editor.canvas.clear();
    }
  };

  const handlePreview = async () => {
    if (!editor) return;
    const json = await JSON.stringify(editor.canvas.toJSON(['id', 'attachEventListeners']));
    const base64Image = await editor.canvas.toDataURL();
    await setSides((prev: any) => ({
      ...prev,
      [side]: { previewUrl: base64Image, canvasUrl: json },
    }));

    setSavet(true)

  }

  const SubmitTemplate = async () => {
    if (!editor || !editor.canvas) {
      console.error("Editor canvas is not initialized.");
      return;
    }
    const json = await JSON.stringify(editor.canvas.toJSON(['id', 'attachEventListeners']));
    const base64Image = await editor.canvas.toDataURL();

    if (fixedSidesAdmin?.result?.length > 0) {
      setTemplatePreviewUrl(sides.front.previewUrl);
      await setCanvasSides({
        templateBackUrl: sides.back.canvasUrl, templateLeftSideUrl: sides.left.canvasUrl, templateRightSideUrl: sides.right.canvasUrl,
        previewBackUrl: sides.back.previewUrl, previewLeftSideUrl: sides.left.previewUrl, previewRightSideUrl: sides.right.previewUrl
      })
      await onChangeHandler(sides.front.canvasUrl);
    } else {
      setTemplatePreviewUrl(base64Image);
      await onChangeHandler(json);
    }
    await onSubmit()
  }

  const addRectangle = (type: string) => {
    if (editor && editor.canvas) {
      let rect;
      /*  if (type === "printableArea") {
         rect = new fabric.Rect({
           left: 50,
           top: 50,
           fill: strokeDetRec.fill,
           width: 100,
           height: 100,
           selectable: true,
           hasControls: true,
           lockUniScaling: true,
           stroke: strokeDetRec.stroke, // Border color
           strokeWidth: parseFloat(strokeDetRec.width.toString()), // Border width
         });
         (rect as any).id = 'printableArea'; // Add ID here
       } else { */
      rect = new fabric.Rect({
        left: 50,
        top: 50,
        fill: strokeDetRec.fill,
        width: 100,
        height: 100,
        selectable: true,
        hasControls: true,
        lockUniScaling: true,
        stroke: strokeDetRec.stroke, // Border color
        strokeWidth: parseFloat(strokeDetRec.width.toString()) // Border width
      });
      /* } */
      editor.canvas.add(rect);
      setSelectedRect(rect);
    }
  };

  /*  const addRectangle = () => {
     if (editor && editor.canvas) {
       const rect = new fabric.Rect({
         left: 50,
         top: 50,
         fill: strokeDetRec.fill,
         width: 100,
         height: 100,
         selectable: true,
         hasControls: true,
         lockUniScaling: true,
         stroke: strokeDetRec.stroke, // Border color
         strokeWidth: parseFloat(strokeDetRec.width.toString())// Border width
       });
       editor.canvas.add(rect);
       setSelectedRect(rect);
     }
   }; */

  const drawLineV = () => {
    if (editor && editor.canvas) {
      const line = new fabric.Line([200, 100, 100, 200], {
        left: 50,
        top: 50,
        angle: -45,
        stroke: lineProperties.stroke,
        strokeWidth: parseFloat(lineProperties.width.toString()),
        selectable: true,
        hasControls: true,
        lockUniScaling: true,
      });
      editor.canvas.add(line);
      setSelectedLine(line); // Select the line after adding
    }
  };
  const drawLineH = () => {
    if (editor && editor.canvas) {
      const line = new fabric.Line([200, 100, 100, 200], {
        left: 50,
        top: 50,
        angle: 45,
        stroke: lineProperties.stroke,
        strokeWidth: parseFloat(lineProperties.width.toString()),
        selectable: true,
        hasControls: true,
        lockUniScaling: true,
      });
      editor.canvas.add(line);
      setSelectedLine(line); // Select the line after adding
    }
  };

  const addImageInput = () => {
    if (editor && editor.canvas) {
      const rect = new fabric.Rect({
        left: 60,
        top: 60,
        fill: '#0767bc',
        stroke: '#000000',
        strokeWidth: 1,
        width: 80,
        height: 80,
        selectable: true,
        hasControls: true,
        lockUniScaling: true,
      });

      const text = new fabric.Textbox('Select\nImage', {
        left: 75,
        top: 75,
        fontSize: 20,
        fill: '#FFFFFF', // Text color white
      });

      const outerRect = new fabric.Rect({
        left: 20, 
        top: 20,
        width: 160,
        height: 160,
        fill: 'rgba(0,0,0,0)',
        stroke: '#fff', 
        strokeWidth: 0,
        selectable: true,
        hasControls: true,
      });
  
      const group = new fabric.Group([outerRect,rect, text], {
        left: 50,
        top: 50,
        selectable: true,
      });
      
      editor.canvas.add(group);
      setInputArea(group);

      /*   group.on('mousedown', () => {
          const imageInput = document.createElement('input');
          imageInput.type = 'file';
          imageInput.accept = 'image/*';
          imageInput.style.display = 'none';
          imageInput.addEventListener('change', function (e) {
            const file = (e.target as HTMLInputElement).files?.[0];
            const reader = new FileReader();
  
            reader.onload = function (event) {
              const imgObj = new Image();
              imgObj.src = event.target?.result as string;
  
              imgObj.onload = function () {
                if (editor.canvas && imgObj.width && imgObj.height && group) {
                  const widthFix = (rect.group?.scaleX && group?.width) ? group?.width * rect.group?.scaleX : group?.width!;
                  const heightFix = (rect.group?.scaleY && group?.height) ? group?.height * rect.group?.scaleY : group?.height!;
                  //console.log(rect.group?.scaleX,rect.group?.scaleY);
                  //console.log(group.left,group.top,widthFix/ imgObj.width,heightFix/ imgObj.height);
                  // console.log(group.left,group.top,group.width! / imgObj.width,group.height! / imgObj.height);
                  const img = new fabric.Image(imgObj);
  
                  img.set({
                    left: group.left,
                    top: group.top,
                    scaleX: widthFix / imgObj.width,
                    scaleY: heightFix / imgObj.height,
                  });
  
  
                  editor.canvas.remove(group);
                  editor.canvas.add(img);
                  setUploadedImage(img);
                  editor.canvas.renderAll();
                }
              };
            };
  
            if (file) {
              reader.readAsDataURL(file);
            }
          });
          imageInput.click();
        });
  
        group.on('scaling', (e) => {
          const target = e.target as fabric.Group;
          if (target && target.type === 'group') {
            const objects = target.getObjects();
            const rect = objects.find((obj) => obj.type === 'rect') as fabric.Rect;
            const text = objects.find((obj) => obj.type === 'text') as fabric.Text;
  
            if (rect && text) {
              text.set({
                left: rect.left! + 5,
                top: rect.top! + 15,
              });
            }
          }
        }); */
    }
  };

  const addText = () => {
    if (!editor) return;

    const textProps: fabric.ITextboxOptions = {
      left: 20,
      top: 20,
      width: 200,
      fontSize: textOptions.fontSize,
      fontFamily: textOptions.fontFamily,
      fill: textOptions.textColor,
      fontStyle: textOptions.fontStyle,
      fontWeight: textOptions.fontWeight,
    };
    const text = new fabric.Textbox('Double click to edit', textProps);
    editor.canvas.add(text);

    text.on('mousedblclick', () => {
      text.enterEditing();
      text.selectAll();
    });

    editor.canvas.setActiveObject(text);
    editor.canvas.renderAll();
  };

  const deleteText = () => {
    if (editor && editor.canvas) {
      const activeObject = editor.canvas.getActiveObject();
      if (activeObject) {
        editor.canvas.remove(activeObject);
      }
    }
  };

  const handleFontSizeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTextOptions({ ...textOptions, fontSize: parseInt(e.target.value) });
    updateActiveText({ fontSize: parseInt(e.target.value) });
  };

  const handleFontFamilyChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setTextOptions({ ...textOptions, fontFamily: e.target.value });
    updateActiveText({ fontFamily: e.target.value });
  };

  const handleTextColorChange = (color: string) => {
    setTextOptions({ ...textOptions, textColor: color });
    updateActiveText({ fill: color });
  };

  const handleFontStyleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const fontStyle = e.target.value as 'normal' | 'italic' | 'oblique';
    setTextOptions({ ...textOptions, fontStyle });
    updateActiveText({ fontStyle });
  };
  const handleTextAlignmentChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const textAlign = e.target.value;
    setTextOptions({ ...textOptions, textAlign });
    updateActiveText({ textAlign });
  };

  const handleFontWeightChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const fontWeight = e.target.value as 'normal' | 'bold' | 'bolder' | 'lighter' | number;
    setTextOptions({ ...textOptions, fontWeight });
    updateActiveText({ fontWeight });
  };

  const handleStrokeRecChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;
    setStrokedetRec(prev => ({ ...prev, [id]: value }));
    if (!editor || !selectedRect) return;

    if (selectedRect) {
      if (id === 'width') {
        const newStrokeWidth = parseFloat(value);
        selectedRect.set({ strokeWidth: newStrokeWidth });
      } else if (id === 'stroke') {
        selectedRect.set({ stroke: value });
      } else if (id === 'fill') {
        selectedRect.set({ fill: value });
      }
      editor.canvas?.renderAll();
    }
  };

  const handleStrokeLineChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;
    setLineProperties(prev => ({ ...prev, [id]: value }));

    if (!editor || !selectedLine) return;
    if (selectedLine && selectedLine.type === 'line') {
      if (id === 'width') {
        const newStrokeWidth = parseFloat(value);
        selectedLine.set({ strokeWidth: newStrokeWidth });
      } else if (id === 'stroke') {
        selectedLine.set({ stroke: value });
      }
      editor.canvas?.renderAll(); // Render canvas after updating line properties
    }
  };

  const handleUnderlineChange = () => {
    setTextOptions({ ...textOptions, underline: !textOptions.underline });
    updateActiveText({ underline: !textOptions.underline });
  };

  const updateActiveText = (options: Partial<fabric.ITextboxOptions>) => {
    if (!editor) return;
    const activeObject = editor.canvas.getActiveObject() as fabric.Textbox;
    if (activeObject && activeObject.type === 'textbox') {
      activeObject.set(options);
      editor.canvas.renderAll();
    }
  };

  const handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file || !editor) return;

    const reader = new FileReader();
    reader.onload = (event) => {
      const imgElement = new Image();
      imgElement.src = event.target?.result as string;
      imgElement.onload = () => {
        const imgInstance = new fabric.Image(imgElement, {
          left: 0,
          top: 0,
          scaleX: editor.canvas.width! / imgElement.width,
          scaleY: editor.canvas.height! / imgElement.height,
          selectable: false,
        });

        editor.canvas.setBackgroundImage(imgInstance, editor.canvas.renderAll.bind(editor.canvas));
      };
    };
    reader.readAsDataURL(file);
  };

  const handleBGColor = (e: React.ChangeEvent<HTMLInputElement>) => {
    const color = e.target.value
    if (editor) {
      editor.canvas.setBackgroundColor(color, () => { editor.canvas.renderAll(); }); // Set the background color
    }
  };

  const deleteBackgroundImage = () => {
    if (editor && editor.canvas) {
      editor.canvas.backgroundImage = undefined;
      editor.canvas.renderAll();
    }
  };

  const handleLocalImage = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file || !editor) return;

    const reader = new FileReader();
    reader.onload = () => {
      fabric.Image.fromURL(reader.result as string, (img) => {
        if (img.width && img.height) {
          img.set({
            left: 10,
            top: 10,
            scaleX: 150 / img.width,
            scaleY: 150 / img.height,
            selectable: true,
            hasControls: true,
            hasBorders: true,
          });
          (img as any).id = 'fixedImage';
          editor.canvas.add(img)
          /* editor.canvas.setOverlayImage(img,()=>{}); */
          editor.canvas.bringForward(img)
          editor.canvas.renderAll();
        } else {
          console.error("Image dimensions are not defined.");
        }
      });
    };
    reader.readAsDataURL(file);
  };

  const downloadCanvasAsImage = () => {
    if (!editor) return;
    const a = document.createElement("a");

    a.href = editor.canvas.toDataURL({
      format: "jpeg",
      quality: 0.8,
    });
    a.download = "preview.jpeg";
    a.click();

    a.remove();
  };

  useEffect(() => {
    if (editor && editor.canvas && canvasDimensions.width && canvasDimensions.height) {
      // editor.canvas.setWidth(canvasDimensions.width);
      // editor.canvas.setHeight(canvasDimensions.height);
      const newHeightCanvas = type === "Edit" ? canvasDimensions.height - 4 : canvasDimensions.height
      //const newWidthCanvas = type==="Edit"?canvasDimensions.width-4:canvasDimensions.width
      editor.canvas.setWidth(canvasDimensions.width);
      editor.canvas.setHeight(newHeightCanvas);
      editor.canvas.preserveObjectStacking = true;
      editor.canvas.renderAll();
    }
  }, [editor, canvasDimensions, type]);

  const handleCanvasReady = (canvas: any) => {
    onReady(canvas);
    if (canvasDimensions.width && canvasDimensions.height) {
      canvas.setWidth(canvasDimensions.width);
      canvas.setHeight(canvasDimensions.height);
    }
    canvas.renderAll();
  };


  /*  const handleDeleteImage = () => {
     if (editor && uploadedImage) {
       editor.canvas.remove(uploadedImage);
       setUploadedImage(null);
       //addImageInput("rect");
       addImageInput();
     }
   };
 
   const handleDeleteInputArea = () => {
     if (editor && inputArea) {
       editor.canvas.remove(inputArea);
       setInputArea(null);
     }
   };
   const addUnderlineToText = () => {
     if (editor && editor.canvas) {
       const activeObject = editor.canvas.getActiveObject() as fabric.Textbox;
       if (activeObject && activeObject.type === 'textbox') {
         activeObject.set({ underline: !activeObject.underline });
         editor.canvas.renderAll();
       }
     }
   }; */

  return (<>
    <div className="flex flex-wrap gap-6 mt-10">
      <div ref={canvasRef} className='my-2 mb-12'>
        {/*<div ref={canvasRef} className='my-2 mb-12' style={{ width: `${canvasDimensions.width}px`, height: `${canvasDimensions.height}px` }}>
         <FabricJSCanvas
          className={`border-2 border-gray-800 w-[200px] h-[800px]`}
          onReady={onReady}  /> */}
        {(canvasDimensions.width > 0 && canvasDimensions.height > 0) && <FabricJSCanvas
          className={`border-2 border-gray-800`}
          onReady={handleCanvasReady} />}
        {fixedSidesAdmin?.result?.length > 0 && <RadioGroupSides side={side} fixedSidesAdmin={fixedSidesAdmin?.result[0]} handleChange={handleChange} />}
      </div>
      <div className="max-w-[650px] my-2 shadow-lg border-[1px] border-gray-500 p-2">
        {/*  <Separator className='bg-gray-400' /> */}
        <div className='flex flex-col gap-3 w-full my-2'>
          <p className="font-bold text-lg">Text Field:</p>
          <div className="flex flex-wrap gap-4 items-center">
            <Button type='button' onClick={addText} size={"sm"} className="bg-blue-500 hover:bg-blue-700">
              Add Text
            </Button>
            <Label className='flex  items-center'>
              Font Size:
              <Input type="range" min="10" max="50" className='w-32' value={textOptions.fontSize} onChange={handleFontSizeChange} />
              {textOptions.fontSize}
            </Label>
            <Label>
              Font:
              <select value={textOptions.fontFamily} onChange={handleFontFamilyChange} className='h-8 w-32 rounded-lg'>
                {fontFamiliesDefault.map(family => <option value={family} key={family}>{family}</option>)}
              </select>
            </Label>
            <Label className='flex gap-1 items-center'>
              Text Color:
              <Input type="color" value={textOptions.textColor} onChange={(e) => handleTextColorChange(e.target.value)} />
            </Label>
            <Label className='flex gap-2 items-center'>
              Font Style:
              {/*  <Button size={"sm"} onClick={()=>{setTextOptions(pre=>({...pre,fontStyle:"normal"}));updateActiveText({ fontStyle:"normal" });}} className={`${textOptions.fontStyle === "normal"?"bg-black":"bg-gray-500"}`} >Normal</Button>
              <Button size={"sm"} onClick={()=>{setTextOptions(pre=>({...pre,fontStyle:"italic"}));updateActiveText({ fontStyle:"italic" });}}  className={`${textOptions.fontStyle === "italic"?"bg-black":"bg-gray-500"}`}  >Italic</Button>
              */} <select value={textOptions.fontStyle} onChange={handleFontStyleChange} className='h-8 w-28 rounded-lg'>
                <option value="normal">Normal</option>
                <option value="italic">Italic</option>
                <option value="oblique">Oblique</option>
              </select>
            </Label>
            <Label className='flex gap-2 items-center'>
              Align Text:
              <select value={textOptions.textAlign} onChange={handleTextAlignmentChange} className='h-8 w-20 rounded-md '>
                <option value="left">Left</option>
                <option value="center">Center</option>
                <option value="right">Right</option>
                <option value="justify">Justify</option>
              </select>
            </Label>
            <Label>
              Font Weight:
              <select value={textOptions.fontWeight} onChange={handleFontWeightChange} className='h-8 w-28 rounded-lg'>
                <option value="normal">Normal</option>
                <option value="bold">Bold</option>
                <option value="bolder">Bolder</option>
                <option value="lighter">Lighter</option>
              </select>
            </Label>
            <Label className='flex items-center gap-2'>
              <Input type="checkbox" checked={textOptions.underline} onChange={handleUnderlineChange} />
              Underline
            </Label>
            {/* <Button onClick={addUnderlineToText} size={"sm"} className="bg-blue-500 hover:bg-blue-700">
              Toggle Underline
            </Button> */}
          </div>
        </div>
        <Separator className='bg-gray-400' />
        <div className="grid grid-cols-2 max-sm:grid-cols-1 gap-2">
          <div className='flex flex-col gap-3 w-full my-2'>
            <p className="font-bold text-lg">Back Ground Image:</p>
            <div className="flex flex-wrap gap-4 items-center">

              {categoryId === 1 ? <Button type='button' size={"sm"} onClick={() => setOpenSheetTemplate(true)} className='bg-green-500'>Add/Edit Background Image</Button> :
                <Label htmlFor="image" className=' p-2 py-2 bg-green-500 rounded-md text-white text-sm'>Add/Edit Background Image</Label>
              }<input
                id="image"
                type="file"
                accept="image/*"
                onChange={handleImageUpload}
                className="hidden px-2 py-2 bg-green-500 text-white rounded hover:bg-green-700"
              />
              <input
                id="color"
                type="color"
                onChange={handleBGColor}
                className="rounded "
              />
              <Button type='button' onClick={deleteBackgroundImage} size="sm" className="bg-red-500 rounded hover:bg-red-700">
                Delete Background Image
              </Button>

            </div>
          </div>
          <div className='flex gap-2'>
            <Separator className='bg-gray-400' orientation="vertical" />
            <div className='flex flex-col gap-3 w-full my-2'>
              <p className="font-bold text-lg">Insert Input Select Image Field:</p>
              <div className="flex flex-wrap gap-5 items-center">
                <Button type='button' onClick={addImageInput} size="sm" className="bg-blue-500 rounded hover:bg-blue-700">
                  Add Image Input
                </Button>
                <Label htmlFor="localImage" className=' p-2 py-2 bg-green-500 rounded-md text-white text-sm'>Add Image</Label>
                <input
                  id="localImage"
                  type="file"
                  accept="image/*"
                  onChange={handleLocalImage}
                  className="hidden px-2 py-2 bg-green-500 text-white rounded hover:bg-green-700"
                />
                <DialogAddTextField editor={editor} textoptionsold={textOptions} />
                <Button type='button' onClick={deleteText} size={"sm"} className="bg-red-500 hover:bg-red-700">
                  Delete Selected
                </Button>
                {/*   {uploadedImage && (
            <Button onClick={handleDeleteImage} size="sm" className="bg-red-500 rounded hover:bg-red-700">
              Delete Image
            </Button>
          )}
          {inputArea && (
            <Button onClick={handleDeleteInputArea} size="sm" className="bg-red-500 rounded hover:bg-red-700">
              Delete Input Area
            </Button>
          )}
 */}
              </div>
            </div>
          </div>
        </div>
        <Separator className='bg-gray-400' />
        <div className="grid grid-cols-2 max-sm:grid-cols-1 gap-2">
          <div className='flex flex-col gap-3 w-full my-2'>
            <p className="font-bold text-lg">Add Rectangle:</p>
            <div className="flex flex-wrap gap-6">
              <Button type='button' onClick={() => addRectangle("rect")} size={"sm"} className="bg-blue-500 hover:bg-blue-700">
                Add Rectangle
              </Button>
              {/*  <Button type='button' onClick={()=>addRectangle("printableArea")} size={"sm"} className="bg-blue-500 hover:bg-blue-700">
                Add Printable Area
              </Button> */}
              <Label className='flex items-center'>
                Border color:
                <Input type="color" id='stroke' className='w-16' value={strokeDetRec.stroke} onChange={handleStrokeRecChange} />
              </Label>
              <Label className='flex items-center'>
                Border Size:
                <Input className='w-32' type="range" min={0} step={.1} max={5} id='width' value={strokeDetRec.width} onChange={handleStrokeRecChange} />
                <p className='mx-2'>{strokeDetRec.width}</p>
              </Label>
              <Label className='flex items-center'>
                Rec Fill color:
                <Input type="color" className='w-16' id='fill' value={strokeDetRec.fill} onChange={handleStrokeRecChange} />
              </Label>

            </div>
          </div>
          <div className='flex gap-2'>
            <Separator className='bg-gray-400' orientation="vertical" />
            <div className='flex flex-col gap-3 w-full my-2'>
              <div className="flex gap-4 justify-between">
                <p className="font-bold text-lg">Draw Line:</p>
                {/*             <div className='shadow-lg flex gap-2 w-[300px] items-center border-2 border-gray-300  rounded-lg text-sm'><IoIosAlert color='red' size={20} className='w-10' /> We can't update the Line fields to the seltected one. we can only update to the last added Line.</div>
 */}       </div>
              <div className="flex flex-wrap gap-6">
                <Button type='button' onClick={drawLineH} size={"sm"} className="bg-blue-500 hover:bg-blue-700">
                  Horizontal Line
                </Button>
                <Button type='button' onClick={drawLineV} size={"sm"} className="bg-blue-500 hover:bg-blue-700">
                  Vertical Line
                </Button>
                <Label className='flex items-center'>
                  Color:
                  <Input type="color" id='stroke' className='w-16' value={lineProperties.stroke} onChange={handleStrokeLineChange} />
                </Label>
                <Label className='flex items-center'>
                  Line width:
                  <Input className='w-20' type="range" min={0} step={.1} max={5} id='width' value={lineProperties.width} onChange={handleStrokeLineChange} />
                  <p className='mx-2'>{lineProperties.width}</p>
                </Label>
                <div className=' text-sm flex gap-2 text-red-500'>* we can only update the latest added Rectangle/Line.</div>
              </div>
            </div>
          </div>
        </div>
        <Separator className='bg-gray-400' />
        <div className='flex flex-col gap-3 w-full my-2'>
          <p className="font-bold text-lg">Add Printable Area:</p>
          <PrintableAreaShapes editor={editor} />
        </div>
      </div>
    </div>
    <div className='flex justify-between gap-4 flex-wrap my-4'>
      <div className="flex gap-6 flex-wrap">
        <Button type='button' onClick={downloadCanvasAsImage} className="headermenu ">Download  Design Image</Button>
        <Button type='button' onClick={handlePreview} className="headermenu ">Complete Design</Button>
        <Button type='submit' onClick={SubmitTemplate} className="headermenu " disabled={!saveT}>Save Changes</Button></div>
      <Button type='button' onClick={() => { navigate(-1) }} className="bg-slate-500 ">Cancel</Button>
    </div>
    {openSheetTemplate && <GetTransparentImages setOpenSheetTemplate={setOpenSheetTemplate} subcategoryId={subCategoryId} categoryId={categoryId} editor={editor} />}
  </>
  );
};



export function RadioGroupSides({ side, handleChange, fixedSidesAdmin }: any) {
  const sidesActive = JSON.parse(fixedSidesAdmin?.attributeValue);

  return (
    <RadioGroup
      defaultValue={side}
      onValueChange={(value: string) => handleChange(value)}
      className="flex gap-2 mt-4 mb-2 flex-wrap"
    >
      {sidesActive?.front && <div className="flex items-center space-x-2">
        <RadioGroupItem value="front" id="front" />
        <Label htmlFor="front">Front Side</Label>
      </div>}
      {sidesActive?.back && <div className="flex items-center space-x-2">
        <RadioGroupItem value="back" id="back" />
        <Label htmlFor="back">Back Side</Label>
      </div>}
      {sidesActive?.sides && <><div className="flex items-center space-x-2">
        <RadioGroupItem value="left" id="left" />
        <Label htmlFor="left">Left Side</Label>
      </div>
        <div className="flex items-center space-x-2">
          <RadioGroupItem value="right" id="right" />
          <Label htmlFor="right">Right Side</Label>
        </div></>}
    </RadioGroup>
  );
}


type getTransparentImagesProps = {
  subcategoryId: number,
  categoryId: number,
  setOpenSheetTemplate: React.Dispatch<React.SetStateAction<boolean>>,
  editor: any
}

export function GetTransparentImages({ subcategoryId, categoryId, setOpenSheetTemplate, editor }: getTransparentImagesProps) {
  const [getTransparentImages, { data: transparentImages }] = useGetTransparentImagesAMutation()
  const [open, setOpen] = useState(true)
  const handleClose = () => {
    setOpen(false)
    setOpenSheetTemplate(false)
  }

  useEffect(() => {
    if (subcategoryId && categoryId) {
      getTransparentImages({ subcategoryId, categoryId })
    }
  }, [subcategoryId, categoryId, getTransparentImages])

  const handleImageUpload = (url: string) => {
    setOpenSheetTemplate(false);

    if (!editor || !editor.canvas) return;

    // Check if url is a valid URL by checking if it starts with 'http' or 'https'
    if (url.startsWith('http://') || url.startsWith('https://')) {
      fetch(url, { mode: 'cors' })
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.blob();
        })
        .then(blob => {
          const reader = new FileReader();
          reader.onload = () => {
            const imageUrl = reader.result as string;
            fabric.Image.fromURL(imageUrl, (img: fabric.Image) => {
              img.set({
                crossOrigin: 'anonymous',
                left: 0,
                top: 0,
                scaleX: editor.canvas.width! / img.width!,
                scaleY: editor.canvas.height! / img.height!,
                selectable: false,
              });
              editor.canvas.setBackgroundImage(img, editor.canvas.renderAll.bind(editor.canvas));
            }, { crossOrigin: 'anonymous' });
          };
          reader.readAsDataURL(blob);
        })
        .catch(error => {
          console.error('Error fetching image:', error);
        });
    }
  };

  return (
    <Sheet open={open} onOpenChange={() => {
      if (!open) {
        setOpen(true);
      } else {
        handleClose();
      }
    }}>
      <SheetContent className="overflow-y-scroll max-h-screen max-w-[350px] " >
        <SheetHeader>
          <SheetTitle className="">Select Template</SheetTitle>
        </SheetHeader>
        <div className="flex gap-4 my-4">
          {transparentImages?.result?.length > 0 && transparentImages?.result?.map((tranparent: any) => <div onClick={() => { handleImageUpload(`${IMGURL}${tranparent.imageUrl}`) }} key={tranparent.id} className="rounded-md w-24 shadow-lg border-2">
            <img src={`${IMGURL}${tranparent.imageUrl}`} alt={tranparent.imageUrl} className='bg-gray-300' />
          </div>)}
        </div>

      </SheetContent>
    </Sheet>
  )
}


export function DialogAddTextField({ editor, textoptionsold }: any) {
  const [open, setOpen] = useState(false)
  /* const [textOptions, setTextOptions] = useState({
    fontSize: 20,
    fontFamily: 'Arial',
    textColor: '#000000',
    fontStyle: 'normal' as 'normal' | 'italic' | 'oblique' | undefined,
    fontWeight: 'normal' as 'normal' | 'bold' | 'bolder' | 'lighter' | number | undefined,
    underline: false,
    textAlign: 'left',
  }); */
  const [textFieldOptions, setTextFieldOptions] = useState({ text: "", field: "" })
  const handleClose = () => {
    setOpen(false);
    setTextFieldOptions({ text: "", field: "" })
  };

  const addTextField = () => {
    if (!editor) return;

    const textProps: fabric.ITextboxOptions = {
      left: 20,
      top: 20,
      width: 200,
      fontSize: textoptionsold.fontSize,
      fontFamily: textoptionsold.fontFamily,
      fill: textoptionsold.textColor,
      fontStyle: textoptionsold.fontStyle,
      fontWeight: textoptionsold.fontWeight,
    };
    
    (textProps as any).textField = textFieldOptions.field;

    const text = new fabric.Textbox(textFieldOptions.text, textProps);
    editor.canvas.add(text);


    text.on('mousedblclick', () => {
      text.enterEditing();
      text.selectAll();
    });

    editor.canvas.setActiveObject(text);
    editor.canvas.renderAll();
    handleClose();
  };

  return (
    <Dialog open={open} onOpenChange={() => {
      if (!open) {
        setOpen(true);
      } else {
        handleClose();
      }
    }}>
      <DialogTrigger asChild>
        <Button className="headermenu">Add Text Field</Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[450px]">
        <DialogHeader>
          <DialogTitle >Add Text Field</DialogTitle>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          <div className="grid grid-cols-4 items-center gap-4">
            <Label htmlFor="name" className="text-right">
              Field Name
            </Label>
            <Input
              id="name"
              value={textFieldOptions.field}
              onChange={(e) => setTextFieldOptions(pre => ({ ...pre, field: e.target.value }))}
              className="col-span-3"
            />
          </div>
          <div className="grid grid-cols-4 items-center gap-4">
            <Label htmlFor="text" className="text-right">
              Text
            </Label>
            <Input
              id="text"
              value={textFieldOptions.text}
              onChange={(e) => setTextFieldOptions(pre => ({ ...pre, text: e.target.value }))}
              className="col-span-3"
            />
          </div>
        </div>
        <Button type="button" onClick={addTextField} className='headermenu'>Add Text Field</Button>
      </DialogContent>
    </Dialog>
  )
}

export default CreateTemplate;
